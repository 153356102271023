import store from '@/store';
import NavigationGuards from './NavigationGuards';

export default [
	{ // patients only
		path: '/profile/scenarios',
		name: 'patientIndexQuiz',
		component: () => import('../pages/quizzes/manageQuizzes'),
		props: (route) => {
			return {
				patientId: store.getters.getUser.id
			};
		},
		beforeEnter: NavigationGuards.quizzesPatientGuard
	},
	{ // researchers only
		path: '/scenarios',
		name: 'indexQuiz',
		component: () => import('../pages/quizzes/manageQuizzes'),
	},
	{ // researchers only
		path: '/scenarios/report',
		name: 'reportQuizzes',
		component: () => import('../pages/quizzes/reportQuizAttempts'),
	},
	{ // researchers only
		path: '/scenarios/create',
		name: 'createQuiz',
		component: () => import('../pages/quizzes/createEditQuiz'),
		props: (route) => {
			return {
				edit: false
			};
		}
	},
	{ // researchers only
		path: '/scenarios/:quizId',
		name: 'showQuiz',
		component: () => import('../pages/quizzes/showQuiz'),
		props: (route) => {
			return {
				quizId: +route.params.quizId
			};
		}
	},
	{ // researchers only
		path: '/scenarios/:quizId/edit',
		name: 'editQuiz',
		component: () => import('../pages/quizzes/createEditQuiz'),
		props: (route) => {
			return {
				quizId: +route.params.quizId
			};
		}
	},
	{ // patients only
		path: '/profile/scenarios/:quizId',
		name: 'patientTakeQuiz',
		//component: () => import('../pages/quizzes/takeQuiz'),
		props: (route) => {
			return {
				patientId: store.getters.getUser.id,
				quizId: +route.params.quizId
			};
		},
		beforeEnter: NavigationGuards.quizzesPatientGuard
	},
	{ // patients only
		path: '/profile/scenarios/dashboard',
		name: 'quizDashboard',
		component: () => import('../pages/quizzes/quizDashboard')
	},
	{ // researchers only
		path: '/scenarios/:quizId/scenario-attempts/',
		name: 'indexQuizAttemptByQuiz',
		component: () => import('../pages/quizzes/manageQuizAttempts'),
		props: (route) => {
			return {
				quizId: +route.params.quizId
			};
		}
	},
	{ // researchers only
		path: '/patients/:patientId/scenario-attempts/',
		name: 'indexQuizAttemptByPatient',
		component: () => import('../pages/quizzes/manageQuizAttempts'),
		props: (route) => {
			return {
				patientId: +route.params.patientId
			};
		}
	},
	{ // patients only
		path: '/profile/scenario-attempts/',
		name: 'patientIndexQuizAttempt',
		component: () => import('../pages/quizzes/manageQuizAttempts'),
		props: (route) => {
			return {
				patientId: store.getters.getUser.id,
			};
		}
	},
	{ // patients only
		path: '/profile/scenarios/:quizId/scenario-attempts/:quizAttemptId',
		name: 'patientReviewQuizAttempt',
		component: () => import('../pages/quizzes/showQuizAttempt'),
		props: (route) => {
			return {
				patientId: store.getters.getUser.id,
				quizId: +route.params.quizId,
				quizAttemptId: +route.params.quizAttemptId
			};
		},
		beforeEnter: NavigationGuards.quizzesPatientGuard
	},
	{ // researchers only
		path: '/scenarios/scenario-attempts/:quizAttemptId',
		name: 'reviewQuizAttempt',
		component: () => import('../pages/quizzes/showQuizAttempt'),
		props: (route) => {
			return {
				quizAttemptId: +route.params.quizAttemptId
			};
		}
	}
];
