<template>
	<div>
		<b-button
			ref="submitButton"
			variant="dark-blue"
			class="float-right ml-2"
			type="submit"
			:disabled="disabled"
		>
			{{ submitText }}
		</b-button>
		<b-button
			variant="secondary"
			class="float-right"
			type="reset"
		>
			{{ resetText }}
		</b-button>
	</div>
</template>
<script>
export default {
	name: 'SubmitResetButtons',
	props: {
		loading: {
			required: true,
			type: Boolean
		},
		disabled: {
			required: false,
			type: Boolean,
			default: () => false
		},
		submitText: {
			required: false,
			type: String,
			default: () => 'Submit'
		},
		resetText: {
			required: false,
			type: String,
			default: () => 'Reset'
		}
	},
	watch: {
		loading: function (newVal, oldVal) {
			if (newVal) {
				this.$refs.submitButton.disabled = true;
				this.$refs.submitButton.innerHTML = '<div class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>';
			}
			else {
				this.$refs.submitButton.disabled = false;
				this.$refs.submitButton.innerHTML = this.submitText;
			}
		}
	}
};
</script>
<style scoped>

</style>
