import Axios from 'axios';
import { API_URL } from '@/store/APIVars';

const actionPlanning = {
	namespaced: true,
	state: () => ({
		actionPlans: [],
		reviewableActionPlans: [],
		completedActionPlans: [],
		activeSortMode: null,
		completedSortMode: null,
		filterMode: null,
		actionResponseOptions: [],
	}),
	getters: {
		actionPlans(state) {
			return state.actionPlans;
		},
		reviewableActionPlans(state) {
			return state.reviewableActionPlans;
		},
		completedActionPlans(state) {
			return state.completedActionPlans;
		},
		activeSortMode(state) {
			return state.activeSortMode;
		},
		completedSortMode(state) {
			return state.completedSortMode;
		},
		filterMode(state) {
			return state.filterMode;
		},
		actionResponseOptions(state) {
			return state.actionResponseOptions;
		},
	},
	mutations: {
		setActionPlans(state, actionPlans) {
			state.actionPlans = actionPlans;
		},
		setReviewableActionPlans(state, reviewableActionPlans) {
			state.reviewableActionPlans = reviewableActionPlans;
		},
		setCompletedActionPlans(state, completedActionPlans) {
			state.completedActionPlans = completedActionPlans;
		},
		addActionPlan(state, actionPlan) {
			state.actionPlans.push(actionPlan);
		},
		updateActionPlan(state, updatedActionPlan) {
			state.actionPlans = state.actionPlans.map(actionPlan => actionPlan.id === updatedActionPlan.id ? updatedActionPlan : actionPlan);
		},
		deleteActionPlan(state, actionPlanID) {
			state.actionPlans = state.actionPlans.filter(actionPlan => actionPlan.id !== actionPlanID);
		},
		setActiveSortMode(state, activeSortMode) {
			state.activeSortMode = activeSortMode;
		},
		setCompletedSortMode(state, completedSortMode) {
			state.completedSortMode = completedSortMode;
		},
		setFilterMode(state, filterMode) {
			state.filterMode = filterMode;
		},
		setActionResponseOptions(state, actionResponseOptions) {
			state.actionResponseOptions = actionResponseOptions;
		},
	},
	actions: {
		async loadActionPlans({ commit, rootGetters }) {
			const response = await Axios.get(`${API_URL}patients/actionplans`, {
				headers: {
					Authorization: `Bearer ${rootGetters.getAuthToken}`
				}
			});
			const actionPlans = response.data.response;
			commit('setActionPlans', actionPlans);
		},
		async loadReviewableActionPlans({ commit, rootGetters }) {
			const response = await Axios.get(`${API_URL}patients/actionplans/reviewable`, {
				headers: {
					Authorization: `Bearer ${rootGetters.getAuthToken}`
				}
			});
			const reviewable = response.data.response;
			commit('setReviewableActionPlans', reviewable);
		},
		async loadCompletedActionPlans({ commit, rootGetters }) {
			const response = await Axios.get(`${API_URL}patients/actionplans/complete`, {
				headers: {
					Authorization: `Bearer ${rootGetters.getAuthToken}`
				}
			});
			const completed = response.data.response;
			commit('setCompletedActionPlans', completed);
		},
		async completeActionPlan({ rootGetters }, payload) {
			/*
			Expecting payload: {actionPlanID}
			*/
			try {
				const response = await Axios.post(`${API_URL}actionplans/${payload.actionPlanID}/complete`, null, {
					headers: {
						Authorization: `Bearer ${rootGetters.getAuthToken}`
					}
				});
				// TODO: should something be committed?
				return response;
			}
			catch(error) {
				return error.response;
			}
		},
		async addActionPlan({ commit, rootGetters }, payload) {
			/*
			Expecting payload: {actionPlan}
			*/
			try {
				const response = await Axios.post(`${API_URL}actionplans`, payload.actionPlan, {
					headers: {
						Authorization: `Bearer ${rootGetters.getAuthToken}`
					}
				});
				commit('addActionPlan', payload.actionPlan);
				return response;
			}
			catch(error) {
				return error.response;
			}
		},
		async updateActionPlan({ commit, rootGetters }, payload) {
			/*
			Expecting payload: {updatedActionPlan}
			*/
			try {
				const response = await Axios.put(`${API_URL}actionplans/${payload.updatedActionPlan.id}`, payload.updatedActionPlan, {
					headers: {
						Authorization: `Bearer ${rootGetters.getAuthToken}`
					}
				});
				commit('updateActionPlan', payload.updatedActionPlan);
				return response;
			}
			catch(error) {
				return error.response;
			}
		},
		async reviewAction({ rootGetters }, payload) {
			/*
			Expecting payload: {actionID, actionResponseOptionID}
			*/
			try {
				const requestBody = {action_response_option_id: payload.actionResponseOptionID};
				const response = await Axios.post(`${API_URL}actionplans/actions/${payload.actionID}/answer`, requestBody, {
					headers: {
						Authorization: `Bearer ${rootGetters.getAuthToken}`
					},
				});
				return response;
			}
			catch(error) {
				return error.response;
			}
		},
		async deleteActionPlan({ commit, rootGetters }, payload) {
			/*
			Expecting payload: {actionPlanID}
			*/
			try {
				const response = await Axios.delete(`${API_URL}actionplans/${payload.actionPlanID}`, {
					headers: {
						Authorization: `Bearer ${rootGetters.getAuthToken}`
					}
				});
				commit('deleteActionPlan', payload.actionPlanID);
				return response;
			}
			catch(error) {
				return error.response;
			}
		},
		async loadActionResponseOptions({ commit, rootGetters }) {
			const response = await Axios.get(`${API_URL}actionplans/action-response-options`, {
				headers: {
					Authorization: `Bearer ${rootGetters.getAuthToken}`
				}
			});
			const actionResponseOptions = response.data.response;
			commit('setActionResponseOptions', actionResponseOptions);
		},
	},
};

export default actionPlanning;