import Axios from 'axios';
// import router from '../../router';
import { API_URL, PATIENTS, TAGS, QUESTIONS } from '@/store/APIVars';
import { apiCall } from '@/store/apiCall';

const tag = {
	namespaced: true,
	state: () => ({
		tags: [],
		tag: null,
	}),
	getters: {
		getTags(state){
			return state.tags;
		},
		getTag(state){
			return state.currentTag;
		}
	},
	mutations: {
		setTags(state, payload){
			state.tags = payload;
		},
		setTag(state, payload){
			state.tag = payload;
		},
	},
	actions: {
		async getAllTags({ commit, getters, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback } = {}) {
			return await apiCall(
				() => Axios.get(API_URL + TAGS, {headers: {Authorization: 'Bearer ' + rootGetters.getAuthToken}}),
				setState || ((data) => commit('setTags', data)),
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async getTag({ commit, getters, rootGetters }, { tagId, setState, modifier, callback, errorCondition, errorCallback } = {}) {
			return await apiCall(
				() => Axios.get(API_URL + TAGS + `/${tagId}`, {headers: {Authorization: 'Bearer ' + rootGetters.getAuthToken}}),
				setState || ((data) => commit('setTag', data)),
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async createTag({ commit, getters, rootGetters }, { formData, setState, modifier, callback, errorCondition, errorCallback } = {}) {
			return await apiCall(
				() => Axios.post(API_URL + TAGS, formData, {headers: {Authorization: 'Bearer ' + rootGetters.getAuthToken}}),
				setState || ((data) => commit('setTag', data)),
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async editTag({ commit, getters, rootGetters }, { formData, setState, modifier, callback, errorCondition, errorCallback } = {}) {
			return await apiCall(
				() => Axios.put(API_URL + TAGS + `/${formData.id}`, formData, {headers: {Authorization: 'Bearer ' + rootGetters.getAuthToken}}),
				setState || ((data) => commit('setTag', data)),
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async deleteTag({ commit, getters, rootGetters }, { tagId, setState, modifier, callback, errorCondition, errorCallback } = {}) {
			return await apiCall(
				() => Axios.delete(API_URL + TAGS + `/${tagId}`, {headers: {Authorization: 'Bearer ' + rootGetters.getAuthToken}}),
				setState,
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async getQuestionTags({ commit, getters, rootGetters }, questionId, { setState, modifier, callback, errorCondition, errorCallback } = {}) {
			return await apiCall(
				() => Axios.get(API_URL + QUESTIONS + `/${questionId}/` + TAGS, { headers: { Authorization: 'Bearer ' + rootGetters.getAuthToken } }),
				setState || ((data) => commit('setTags', data)),
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
	},
};

export default tag;
