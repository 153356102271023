import Axios from 'axios';
// import router from '../../router';
import { API_URL, MEASUREMENTS, WEIGHTS, PATIENTS } from '@/store/APIVars';
import { apiCall } from '@/store/apiCall';


const measurement = {
	namespaced: true,
	state: () => ({}),
	getters: {},
	mutations: {},
	actions: {
		async getWeights(
			{
				commit,
				getters,
				rootGetters
			}, {
				setState,
				modifier,
				callback,
				errorCondition,
				errorCallback
			} = {}
		) {
			return await apiCall(
				() => Axios.get(
					API_URL + PATIENTS + '/' + MEASUREMENTS + '/' + WEIGHTS,
					{headers: {Authorization: 'Bearer ' + rootGetters.getAuthToken}}
				),
				setState,
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
	},
};

export default measurement;
