import Axios from 'axios';
// import router from '../../router';
import { API_URL, DAILYQUESTION } from '@/store/APIVars';
import { apiCall } from '@/store/apiCall';

const dailyQuestion = {
	namespaced: true,
	state: () => ({
		dailyQuestion: null,
	}),
	getters: {
		getDailyQuestion(state){
			return state.dailyQuestion;
		}
	},
	mutations: {
		setDailyQuestion(state, payload){
			state.dailyQuestion = payload;
		},
	},
	actions: {
		async getNextDailyQuestion({ commit, getters, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback } = {}) {
			return await apiCall(
				() => Axios.get(API_URL + DAILYQUESTION + '/next', {headers: {Authorization: 'Bearer ' + rootGetters.getAuthToken}}),
				setState || ((data) => commit('setDailyQuestion', data)),
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async submitDailyQuestionResponse({ commit, getters, rootGetters }, { formData, setState, modifier, callback, errorCondition, errorCallback } = {}) {
			return await apiCall(
				() => Axios.post(API_URL + DAILYQUESTION + `/${formData.id}/answer`, formData, {headers: {Authorization: 'Bearer ' + rootGetters.getAuthToken}}),
				setState,
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
	},
};

export default dailyQuestion;
