const reactNativeApp = {
	namespaced: true,
	state: () => ({}),
	getters: {},
	mutations: {},
	actions: {
		sendMessageToApp({ commit, getters, rootGetters }, toSend) {
			if (window.isNativeApp && window.ReactNativeWebView)
				window.ReactNativeWebView.postMessage(toSend);
			// fail silently
		},
	},
};

export default reactNativeApp;
