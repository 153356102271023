import Axios from 'axios';
// import router from '../../router';
import { API_URL, PATIENTS, STUDIES, GROUPS } from '@/store/APIVars';
import { apiCall } from '@/store/apiCall';


const study = {
	namespaced: true,
	state: () => ({
		studies: [],
		currentStudy: null,
	}),
	getters: {
		getStudies(state){
			return state.studies;
		},
		getStudy(state){
			return state.currentStudy;
		}
	},
	mutations: {
		setStudies(state, payload){
			state.studies = payload;
		},
		setCurrentStudy(state, payload){
			state.currentStudy = payload;
		},
		addStudy(state, payload){
			if (Array.isArray(payload)) {
				for (const study of payload)
					state.studies.push(study);
			}
			else
				state.studies.push(payload);
		}
	},
	actions: {
		async addFakeStudies({commit}, payload){
			commit('addStudy', payload);
		},
		async setFakeStudies({commit}, payload){
			commit('setStudies', payload);
		},
		// API calls
		async getAllStudies({ commit, getters, rootGetters }) {
			await Axios.get(API_URL + STUDIES, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('setStudies', data.data.response);
				})
				.catch((err) => console.error(err));
		},
		async getMyStudies({ commit, getters, rootGetters }, { researcherid, setState, modifier, callback, errorCondition, errorCallback }) {
			return await apiCall(
				() => Axios.get(API_URL + "researchers" + `/${researcherid}/` +STUDIES, { headers: { Authorization: 'Bearer ' + rootGetters.getAuthToken } }),
				setState,
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async getStudy({ commit, getters, rootGetters }, study_id) {
			return await Axios.get(API_URL + STUDIES + `/${study_id}`, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					console.log('Data', data);
					commit('addStudy', data.data.response);
					console.log('Study: ', data.data.response);
					commit('setCurrentStudy', data.data.response);
				})
				.catch((err) => console.error(err));
		},
		async getMyStudies({ commit, getters, rootGetters }, { researcherid, setState, modifier, callback, errorCondition, errorCallback }) {
			return await apiCall(
				() => Axios.get(API_URL + "researchers" + `/${researcherid}/` +STUDIES, { headers: { Authorization: 'Bearer ' + rootGetters.getAuthToken } }),
				setState,
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async createStudy({ commit, getters, rootGetters }, { formData, setState, modifier, callback, errorCondition, errorCallback } = {}) {
			return await apiCall(
				() => Axios.post(API_URL + STUDIES, formData, {headers: {Authorization: 'Bearer ' + rootGetters.getAuthToken}}),
				setState,
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async getStudyPrimaryResearcher({ commit, getters, rootGetters }, { studyId, setState, modifier, callback, errorCondition, errorCallback }) {
			return await apiCall(
				() => Axios.get(API_URL + STUDIES + `/${studyId}/` + "primary-researchers", { headers: { Authorization: 'Bearer ' + rootGetters.getAuthToken } }),
				setState,
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async deleteStudy({ commit, getters, rootGetters }, { studyId, setState, modifier, callback, errorCondition, errorCallback }) {
			return await apiCall(
				() => Axios.delete(API_URL + STUDIES + `/${studyId}`, { headers: { Authorization: 'Bearer ' + rootGetters.getAuthToken } }),
				setState,
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async getStudySecondaryResearcher({ commit, getters, rootGetters }, { studyId, setState, modifier, callback, errorCondition, errorCallback }) {
			return await apiCall(
				() => Axios.get(API_URL + STUDIES + `/${studyId}/` + "secondary-researchers", { headers: { Authorization: 'Bearer ' + rootGetters.getAuthToken } }),
				setState,
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async editStudy({ commit, getters, rootGetters }, { formData, setState, modifier, callback, errorCondition, errorCallback } = {}) {
			return await apiCall(
				() => Axios.put(API_URL + STUDIES + `/${formData.id}`, formData, {headers: {Authorization: 'Bearer ' + rootGetters.getAuthToken}}),
				setState,
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async getStudyPatients({ commit, getters, rootGetters }, study_id) {
			await Axios.get(API_URL + STUDIES + `/${study_id}/` + PATIENTS, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('patients/setPatients', data.data.response, { root: true });
				})
				.catch((err) => console.error(err));
		},
		async getStudyGroups({ commit, getters, rootGetters }, { studyId, setState, modifier, callback, errorCondition, errorCallback }) {
			return await apiCall(
				() => Axios.get(API_URL + STUDIES + `/${studyId}/` + GROUPS, { headers: { Authorization: 'Bearer ' + rootGetters.getAuthToken } }),
				setState,
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async getStudyApiCallTest({ commit, getters, rootGetters }, { study_id, setState, modifier, callback, errorCondition, errorCallback }) {
			return await apiCall(
				() => Axios.get(API_URL + STUDIES + `/${study_id}`, {headers: {Authorization: 'Bearer ' + rootGetters.getAuthToken}}),
				setState || ((data) => commit('setCurrentStudy', data)),
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
	},
};

export default study;
