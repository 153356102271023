import Axios from 'axios';
import {API_URL, QUIZZES, QUESTION_CATEGORIES, ANSWERS, QUESTIONS, QUIZ_ATTEMPTS, QUESTION_RESPONSES, PATIENTS, TAGS, GROUPS} from '@/store/APIVars';
import { apiCall } from '@/store/apiCall';

const quiz = {
	namespaced: true,
	state: () => ({
		quizzes: [],
		question_categories: [],
		questions: [],
		answers: [],
		quiz_attempts: [],
		question_responses: []
	}),
	getters: {
		getQuizzes(state){
			return state.quizzes;
		},
		getQuestionCategories(state) {
			return state.question_categories;
		},
		getQuestions(state) {
			return state.questions;
		},
		getAnswers(state) {
			return state.answers;
		},
		getQuizAttempts(state) {
			return state.quiz_attempts;
		},
		getQuestionResponses(state) {
			return state.question_responses;
		}
	},
	mutations: {
		setQuizzes(state, payload) {
			state.quizzes = payload;
			// console.log(state.quizzes);
		},
		addQuiz(state, payload) {
			state.quizzes.push(payload);
		},
		setQuestionCategories(state, payload) {
			state.question_categories = payload;
			// console.log('categories set to the following');
			// console.log(state.question_categories);
		},
		addQuestionCategory(state, payload) {
			// console.log(state.question_categories);
			// console.log(typeof (state.question_categories));
			// console.log(payload);
			state.question_categories.push(payload);
		},
		setQuestions(state, payload) {
			state.questions = payload;
		},
		addQuestion(state, payload) {
			state.questions.push(payload);
		},
		setAnswers(state, payload) {
			state.answers = payload;
		},
		addAnswer(state, payload) {
			// console.log(state.answers);
			state.answers.push(payload);
		},
		setQuizAttempts(state, payload) {
			state.quiz_attempts = payload;
		},
		addQuizAttempt(state, payload) {
			// console.log(state.quiz_attempts);
			// console.log(typeof (state.quiz_attempts));
			state.quiz_attempts.push(payload);
		},
		setQuestionResponses(state, payload) {
			state.question_responses = payload;
		},
		addQuestionResponse(state, payload) {
			state.question_responses.push(payload);
		}
	},
	actions: {
		// API calls
		async getAllQuestionCategories({ commit, getters, rootGetters }) {
			await Axios.get(API_URL + QUESTION_CATEGORIES, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			}).then((data) => {
				commit('setQuestionCategories', data.data.response);
			}).catch((err) => console.error(err));
		},
		async getAllQuestions({commit, getters, rootGetters }) {
			await Axios.get(API_URL + QUESTIONS, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			}).then((data) => {
				commit('setQuestions', data.data.response);
			}).catch((err) => console.error(err));
		},
		async getAllQuizzes({ commit, getters, rootGetters }) {
			await Axios.get(API_URL + QUIZZES, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('setQuizzes', data.data.response);
				})
				.catch((err) => console.error(err));
		},
		async getQuizzesForPatient({ commit, getters, rootGetters }) {
			await Axios.get(API_URL + PATIENTS + '/' + QUIZZES, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('setQuizzes', data.data.response);
				})
				.catch((err) => console.error(err));
		},
		async getQuizzesForGroup({ commit, getters, rootGetters }, id) {
			await Axios.get(API_URL + GROUPS + `/${id}/` + QUIZZES, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			}).then((data) => {
				commit('setQuizzes', data.data.response);
			})
				.catch((err) => console.error(err));
		},
		async getAllQuizAttempts({ commit, getters, rootGetters }) {
			await Axios.get(API_URL + QUIZ_ATTEMPTS, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			}).then((data) => {
				commit('setQuizAttempts', data.data.response);
			}).catch((err) => console.error(err));
		},
		async getQuestionCategory({ commit, getters, rootGetters }, id) {
			await Axios.get(API_URL + QUESTION_CATEGORIES + `/${id}`, {
				headers: {
					Authorization: 'Bearer ' + getters.getAuthToken
				}}).then((data) => {
				commit('setQuestionCategories', data.data.response);
			}).catch((err) => console.error(err));
		},
		async getQuiz({ commit, getters, rootGetters }, id) {
			await Axios.get(API_URL + QUIZZES + `/${id}`, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('setQuizzes', data.data.response);
				})
				.catch((err) => console.error(err));
		},
		async getQuestion({ commit, getters, rootGetters }, id) {
			await Axios.get(API_URL + QUESTIONS + `/${id}`, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('setQuestions', data.data.response);
				})
				.catch((err) => console.error(err));
		},
		async getQuizAttempt({ commit, getters, rootGetters }, id) {
			await Axios.get(API_URL + QUIZ_ATTEMPTS + `/${id}`, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			}).then((data) => {
				commit('setQuizAttempts', data.data.response);
			}).catch((err) => console.error(err));
		},
		async getAnswersByQuestion ({ commit, getters, rootGetters }, question_id) {
			await Axios.get(API_URL + QUESTIONS + `/${question_id}/` + ANSWERS, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('setAnswers', data.data.response);
				})
				.catch((err) => console.error(err));
		},
		async getQuestionsByCategory ({ commit, getters, rootGetters }, question_category_id) {
			await Axios.get(API_URL + QUESTION_CATEGORIES + `/${question_category_id}/` + QUESTIONS, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			}).then((data) => {
				commit('setQuestions', data.data.response);
			}).catch((err) => console.error(err));
		},
		async getQuestionsByQuiz ({ commit, getters, rootGetters }, quiz_id) {
			await Axios.get(API_URL + QUIZZES + `/${quiz_id}/` + QUESTIONS, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			}).then((data) => {
				commit('setQuestions', data.data.response);
			}).catch((err) => console.error(err));
		},
		async getQuizAttemptsByQuiz({ commit, getters, rootGetters }, quiz_id) {
			await Axios.get(API_URL + QUIZZES + `/${quiz_id}/` + QUIZ_ATTEMPTS, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			}).then((data) => {
				commit('setQuizAttempts', data.data.response);
			}).catch((err) => console.error(err));
		},
		async getQuizAttemptsByPatient({ commit, getters, rootGetters }, patient_id) {
			await Axios.get(API_URL + PATIENTS + `/${patient_id}/` + QUIZ_ATTEMPTS, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			}).then((data) => {
				commit('setQuizAttempts', data.data.response);
			}).catch((err) => console.error(err));
		},
		async getQuestionResponsesByQuizAttempt({ commit, getters, rootGetters }, quiz_attempt_id) {
			await Axios.get(API_URL + QUIZ_ATTEMPTS + `/${quiz_attempt_id}/` + QUESTION_RESPONSES, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			}).then((data) => {
				commit('setQuestionResponses', data.data.response);
			}).catch((err) => console.error(err));
		},
		async createQuiz({ commit, getters, rootGetters }, payload){
			// alert("about to add quiz")
			return await Axios.post(API_URL + QUIZZES,
				payload,
				{
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}
			)
				.then((data) => {
					commit('addQuiz', data.data.response);
					return data.data.response.id;
				})
				.catch((err) => {
					if (err.response.status === 422) {
						return -1;
					}
					else {
						console.error(err);
					}
				});
		},
		async createQuestionCategory({ commit, getters, rootGetters }, payload) {
			return await Axios.post(API_URL + QUESTION_CATEGORIES,
				payload,
				{
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}
			).then((data) => {
				commit('addQuestionCategory', data.data.response);
				// alert('actual category id is ' + data.data.response.id);
				return data.data.response.id;
			})
				.catch((err) => {
					console.error(err);
					if (err.response.status === 422) {
						return -1;
					}
					else {
						console.error(err);
					}
				});
		},
		async createQuestion({ commit, getters, rootGetters }, payload) {
			return await Axios.post(API_URL + QUESTIONS,
				payload,
				{
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken,
						'Content-Type': 'multipart/form-data'
					},
				}
			).then((data) => {
				// alert('actual question id is ' + data.data.response.id);
				commit('addQuestion', data.data.response);
				return data.data.response.id;
			}).catch((err) => {
				// console.log(payload.entries());
				if (err.response && err.response.status === 422) {
					return -1;
				}
				else {
					console.error(err);
				}
			});
		},
		async createAnswer({ commit, getters, rootGetters }, payload) {
			await Axios.post(API_URL + ANSWERS,
				payload,
				{
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}
			).then((data) => {
				// alert('about to commit');
				commit('addAnswer', data.data.response);
				// alert('committed');
			}).catch((err) => console.error(err));
		},
		async createQuizAttempt({ commit, getters, rootGetters }, payload) {
			return await Axios.post(API_URL + QUIZ_ATTEMPTS,
				payload,
				{
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}).then((data) => {
				commit('addQuizAttempt', data.data.response);
				return data.data.response.id;
			}).catch((err) => console.error(err));
		},
		async createQuestionResponse({ commit, getters, rootGetters }, payload) {
			await Axios.post(API_URL + QUESTION_RESPONSES,
				payload,
				{
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}).then((data) => {
				commit('addQuestionResponse', data.data.response);
				// alert('added ' + data.data.response.question_text);
			}).catch((err) => console.error(err));
		},
		async deleteQuiz({ commit, getters, rootGetters }, id) {
			await Axios.delete(API_URL + QUIZZES + `/${id}`, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('setQuizzes', data.data.response);
				})
				.catch((err) => console.error(err));
		},
		async deleteQuestion({ commit, getters, rootGetters }, id) {
			await Axios.delete(API_URL + QUESTIONS + `/${id}`, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			}).then((data) => {
				commit('setQuestions', data.data.response);
			}).catch((err) => console.error(err));
		},
		async deleteQuestionCategory({ commit, getters, rootGetters }, id) {
			await Axios.delete(API_URL + QUESTION_CATEGORIES + `/${id}`, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			}).then((data) => {
				commit('setQuestionCategories', data.data.response);
			}).catch((err) => console.error(err));
		},
		async deleteAnswer({ commit, getters, rootGetters }, id) {
			await Axios.delete(API_URL + ANSWERS + `/${id}`,
				{
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					}
				}).then((data) => {
				commit('setAnswers', data.data.response);
			}).catch((err) => console.error(err));
		},
		async updateQuestionCategory({ commit, getters, rootGetters }, payload) {
			return await Axios.put(API_URL + QUESTION_CATEGORIES + `/${payload.id}`, 
				payload.formatted,
				{
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					}
				}
			).then((data) => {
				commit('setQuestionCategories', data.data.response);
				return data.data.response.id;
			}).catch((err) => {
				if (err.response.status === 422) {
					return -1;
				}
				else {
					console.error(err);
				}
			});
		},
		async updateQuestion({ commit, getters, rootGetters }, payload) {
			// console.log(payload.get('tags'));
			return await Axios.post(API_URL + QUESTIONS + `/${payload.get('id')}`,
				payload,
				{
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken,
						'Content-Type': 'multipart/form-data'
					}
				}).then((data) => {
				commit('setQuestions', data.data.response);
				return data.data.response.id;
			}).catch((err) => {
				if (err.response.status === 422) {
					return -1;
				}
				else {
					console.error(err);
				}
			});
		},
		async updateQuiz({ commit, getters, rootGetters }, payload) {
			return await Axios.put(API_URL + QUIZZES + `/${payload.id}`,
				payload.formatted,
				{
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					}
				}
			).then((data) => {
				commit('setQuizzes', data.data.response);
				return data.data.response.id;
			}).catch((err) => {
				if (err.response.status === 422) {
					return -1;
				}
				else {
					console.error(err);
				}
			});
		},
		async updateAnswer({ commit, getters, rootGetters }, payload) {
			// alert('Answer id is ' + payload.id + ' and text is ' + payload.formatted.answer_text);
			await Axios.put(API_URL + ANSWERS + `/${payload.id}`,
				payload.formatted,
				{
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					}
				}).then((data) => {
				commit('setAnswers', data.data.response);
			}).catch((err) => console.error(err));
		},
		async getCurrentPatientQuizAttempts({ commit, getters, rootGetters }) {
			await Axios.get(API_URL + PATIENTS + '/' + QUIZ_ATTEMPTS,
				{
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					}
				}).then((data) => {
				// alert(' about to set quiz attempts');
				commit('setQuizAttempts', data.data.response);
			}).catch((err) => console.error(err));
		},
		async getCurrentPatientQuizAttemptsByQuiz({ commit, getters, rootGetters }, quiz_id) {
			await Axios.get(API_URL + PATIENTS + '/' + QUIZZES + `/${quiz_id}/` + QUIZ_ATTEMPTS,
				{
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					}
				}).then((data) => {
				commit('setQuizAttempts', data.data.response);
			}).catch((err) => console.log(err.statusCode));
		},
		async getTagQuestions({ commit, getters, rootGetters }, tagId, { setState, modifier, callback, errorCondition, errorCallback } = {}) {
			return await apiCall(
				() => Axios.get(API_URL + TAGS + `/${tagId}/` + QUESTIONS, { headers: { Authorization: 'Bearer ' + rootGetters.getAuthToken } }),
				setState || ((data) => commit('setQuestions', data)),
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
	},
};

export default quiz;
