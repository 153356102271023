import store from '@/store';
import NavigationGuards from './NavigationGuards';

export default [
	{ // patients only
		path: '/profile/actionplans',
		name: 'patientIndexActionPlan',
		component: () => import('../pages/actionPlanning/ActionPlansIndexPage'),
		props: (route) => {
			return {
				patientId: store.getters.getUser.id
			};
		},
		beforeEnter: NavigationGuards.actionplansPatientGuard
	},
	{ // patients only
		path: '/profile/actionplans/create',
		name: 'patientCreateActionPlan',
		component: () => import('../pages/actionPlanning/CreateActionPlanPage'),
		props: (route) => {
			return {
				patientId: store.getters.getUser.id
			};
		},
		beforeEnter: NavigationGuards.actionplansPatientGuard
	},
	{ // patients only
		path: '/profile/actionplans/:actionPlanId/review',
		name: 'patientReviewActionPlan',
		component: () => import('@/pages/actionPlanning/ReviewActionPlanPage'),
		props: (route) => {
			return {
				patientId: store.getters.getUser.id,
				actionPlanId: route.params.actionPlanId,
				actionPlan: route.params.actionPlan,
			};
		},
		beforeEnter: NavigationGuards.actionplansPatientGuard
	},
	{ // patients only
		path: '/profile/actionplans/:actionplanId',
		name: 'patientShowActionplan',
		//component: () => import('../pages/actionPlanning/showActionplan'),
		props: (route) => {
			return {
				patientId: store.getters.getUser.id,
				actionplanId: +route.params.actionplanId
			};
		},
		beforeEnter: NavigationGuards.actionplansPatientGuard
	},
	{ // patients only
		path: '/profile/actionplans/:actionPlanId/edit',
		name: 'patientEditActionPlan',
		component: () => import('../pages/actionPlanning/EditActionPlanPage'),
		props: (route) => {
			return {
				patientId: store.getters.getUser.id,
				actionPlan: route.params.actionPlan,
				actionPlanId: route.params.actionPlanId,
			};
		},
		beforeEnter: NavigationGuards.actionplansPatientGuard
	},
	{
		path: '/responses', // FIXME: no idea what this path should be
		name: 'patientViewResponses',
		component: () => import('../pages/actionPlanning/ViewResponsesPage.vue'),
		props: (route) => {
			return {
				actionPlan: route.params.actionPlan,
			};
		},
		beforeEnter: NavigationGuards.actionplansPatientGuard
	},
	{
		path: '/actionplans/help',
		name: 'actionPlansHelp',
		component: () => import('../pages/actionPlanning/ActionPlanHelpPage.vue'),
	}
];