import Axios from 'axios';
// import router from '../../router';
import { API_URL, GROUPS, PATIENTS, QUIZZES } from '@/store/APIVars';
import { apiCall } from '@/store/apiCall';


const study = {
	namespaced: true,
	state: () => ({
		groups: [],
		group: null,
	}),
	getters: {
		getGroups(state){
			return state.groups;
		},
		getGroup(state){
			return state.group;
		}
	},
	mutations: {
		setGroups(state, payload){
			state.groups = payload;
		},
		setGroup(state, payload){
			state.group = payload;
		}
	},
	actions: {
		// API calls
		async getAllGroups({ commit, getters, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback } = {}) {
			return await apiCall(
				() => Axios.get(API_URL + GROUPS, {headers: {Authorization: 'Bearer ' + rootGetters.getAuthToken}}),
				setState || ((data) => commit('setGroups', data)),
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async getGroup({ commit, getters, rootGetters }, { groupId, setState, modifier, callback, errorCondition, errorCallback } = {}) {
			return await apiCall(
				() => Axios.get(API_URL + GROUPS + `/${groupId}`, {headers: {Authorization: 'Bearer ' + rootGetters.getAuthToken}}),
				setState || ((data) => commit('setGroup', data)),
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async getStudy({ commit, getters, rootGetters }, { groupId, setState, modifier, callback, errorCondition, errorCallback } = {}) {
			return await apiCall(
				() => Axios.get(API_URL + GROUPS + `/${groupId}`, {headers: {Authorization: 'Bearer ' + rootGetters.getAuthToken}}),
				setState || ((data) => commit('studies/setStudy', data, { root: true })),
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async createGroup({ commit, getters, rootGetters }, { formData, setState, modifier, callback, errorCondition, errorCallback } = {}) {
			return await apiCall(
				() => Axios.post(API_URL + GROUPS, formData, {headers: {Authorization: 'Bearer ' + rootGetters.getAuthToken}}),
				setState || ((data) => commit('setGroup', data)),
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async editGroup({ commit, getters, rootGetters }, { formData, setState, modifier, callback, errorCondition, errorCallback } = {}) {
			return await apiCall(
				() => Axios.put(API_URL + GROUPS + `/${formData.id}`, formData, {headers: {Authorization: 'Bearer ' + rootGetters.getAuthToken}}),
				setState || ((data) => commit('setGroup', data)),
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async getGroupPatients({ commit, getters, rootGetters }, { groupId, setState, modifier, callback, errorCondition, errorCallback } = {}) {
			return await apiCall(
				() => Axios.get(API_URL + GROUPS + `/${groupId}/` + PATIENTS, { headers: { Authorization: 'Bearer ' + rootGetters.getAuthToken } }),
				setState || ((data) => commit('patients/setPatients', data, { root: true })),
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async getGroupStudy({ commit, getters, rootGetters }, { groupId, setState, modifier, callback, errorCondition, errorCallback } = {}) {
			return await apiCall(
				() => Axios.get(API_URL + GROUPS + `/${groupId}/` + 'study', { headers: { Authorization: 'Bearer ' + rootGetters.getAuthToken } }),
				setState || ((data) => commit('studies/setStudy', data, { root: true })),
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async getGroupsByQuiz({ commit, getters, rootGetters }, quiz_id) {
			await Axios.get(API_URL + QUIZZES + `/${quiz_id}/` + GROUPS, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			}).then((data) => {
				commit('setGroups', data.data.response);
			}).catch((err) => console.error(err));
		}
	},
};

export default study;
