<template>
	<div id="app" class="main-background">
		<Navbar v-if="$store.getters.getUser"></Navbar>
		<router-view class="pt-4" />
	</div>
</template>

<script>
import Navbar from '@/components/Navbar';

export default {
	name: 'App',
	components: {
		Navbar,
	},
	data() {
		return {
			auth: false
		};
	},
	mounted() {
		this.$store.dispatch('reactNativeApp/sendMessageToApp', 'sup, token was ' + localStorage.getItem('auth-token')
			+ 'did error: ' + window.NativeErrored);
	}
};
</script>

<style lang="scss">
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
nav {
	padding: 30px;

	a {
		font-weight: bold;
		color: #2c3e50;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}
#app {
	min-height: 100vh;
}
</style>
