
const form = {
	state: () => ({
		currentTab: 0,
		v: null,
	}),
	mutations: {
		setCurrentTab(newValue) {
			this.state.currentTab = newValue;
		},
		setValidation(newValue) {
			this.state.v = newValue;
		},
	},
	actions: {},
	getters: {
		getCurrentTab: (state) => state.currentTab,
		getState: (state) => state,
	},
};

export default form;