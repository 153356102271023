import Axios from 'axios';
import {API_URL, ENTRIES, PATIENTS} from '@/store/APIVars';
import { apiCall } from '@/store/apiCall';

const entry = {
	namespaced: true,
	state: () => ({
		entries: [],
	}),
	getters: {
		getEntries(state){
			return state.entries;
		}
	},
	mutations: {
		setEntries(state, payload){
			state.entries = payload;
		},
		addEntry(state, payload){
			state.entries.push(payload);
		}
	},
	actions: {
		// API calls
		async getAllEntries({ commit, getters, rootGetters }) {
			await Axios.get(API_URL + ENTRIES, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('setEntries', data.data.response);
				})
				.catch((err) => console.error(err));
		},
		async getEntry({ commit, getters, rootGetters }, entry_id) {
			await Axios.get(API_URL + ENTRIES + `/${entry_id}`, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			}).then((data) => {
				commit('addEntry', data.data.response);
			}).catch((err) => console.error(err));
		},
		async createEntry({ commit, getters, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback, formData } = {}) {
			await apiCall(
				() => Axios.post(API_URL + PATIENTS + '/' + ENTRIES, formData, {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState ? setState : (data) => { commit('setEntries', [data]); },
				modifier,
				callback,
				errorCondition,
				errorCallback ? errorCallback : (err) => console.error(err)
			);
		},
		
	},
};

export default entry;
