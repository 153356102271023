
export default [
	{ // researchers only
		path: '/tags',
		name: 'indexTag',
		component: () => import('../pages/tags/indexTag')
	},
	{ // researchers only
		path: '/tags/create',
		name: 'createTag',
		component: () => import('../pages/tags/modifyTag'),
		props: () => {
			return { edit: false };
		}
	},
	{ // researchers only
		path: '/tags/:tagId',
		name: 'showTag',
		component: () => import('../pages/tags/showTag'),
		props: (route) => {
			return { tagId: +route.params.tagId };
		}
	},
	{ // researchers only
		path: '/tags/:tagId/edit',
		name: 'editTag',
		component: () => import('../pages/tags/modifyTag'),
		props: (route) => {
			return {
				edit: true,
				tagId: +route.params.tagId
			};
		}
	}
];