import store from '@/store';
import NavigationGuards from './NavigationGuards';

export default [
	{ // patients only
		path: '/profile/medications',
		name: 'patientIndexMedication',
		component: () => import('../pages/medications/indexMedications'),
		props: (route) => {
			return {
				patientId: store.getters.getUser.id
			};
		},
		beforeEnter: NavigationGuards.medicationsPatientGuard
	},
	{ // researchers only
		path: '/medications',
		name: 'indexMedication',
		component: () => import('../pages/medications/indexMedications')
	},
	{ // researchers only
		path: '/medications/create',
		name: 'createMedication',
		component: () => import('../pages/medications/createMedication.vue'),
	},
	{ // patients only
		path: '/profile/medications/:medicationId',
		name: 'patientViewMedication',
		component: () => import('../pages/medications/viewMedication'),
		props: (route) => {
			return {
				patientId: store.getters.getUser.id,
				medicationId: +route.params.medicationId
			};
		},
		beforeEnter: NavigationGuards.medicationsPatientGuard
	},
	{ // researchers only
		path: '/medications/:medicationId',
		name: 'viewMedication',
		component: () => import('../pages/medications/viewMedication.vue'),
		props: (route) => {
			return {
				medicationId: +route.params.medicationId
			};
		}
	},
	{ // researchers only
		path: '/medications/:medicationId/modify',
		name: 'modifyMedication',
		component: () => import('../pages/medications/modifyMedication'),
		props: (route) => {
			return {
				medicationId: +route.params.medicationId
			};
		}
	},
	/*{
		path: '/medications',
		name: 'allmedications',
		component: () => import('../pages/medications/medicationslist')
	},
	{
		path: '/medications/create',
		name: 'createmedication',
		// component: () => import('../pages/medications/createmedication')
	},*/
	// {
	// 	path: '/medications/showmedication/:medicationId',
	// 	name: 'showmedication',
	// 	component: () => import('../pages/medications/readmedication'),
	// 	props: (route) => {
	// 		return {
	// 			medicationId: route.params.medicationId,
	// 			patientId: route.params.patientId
	// 		}
	// 	}
	// },
	// {
	// 	path: '/medications/managemedicationschedule/:medSchedule_id',
	// 	name: 'managemedicationschedule',
	// 	component: () => import('../pages/medications/manageMedicationSchedule'),
	// 	props: (route) => {
	// 		return {
	// 			medSchedule_id: +route.params.medSchedule_id
	// 		};
	// 	}
	// },
	{
		path: '/medications/delete/:medicationId',
		name: 'deletemedication',
		// component: () => import('../pages/medications/deletemedication'),
		props: (route) => {
			return {
				medicationId: +route.params.medicationId,
				patientId: +route.params.patientId
			};
		}
	},
	// {
	// 	path: '/medications/managemedicationschedules',
	// 	name: 'managemedicationschedules',
	// 	component: () => import('../pages/medications/manageMedicationSchedules')
	// },
	// {
	// 	path: '/medications/record',
	// 	name: 'recordmedication',
	// 	component: () => import('../pages/medications/recordmedicationroutine')
	// },
	// {
	// 	path: '/medications/createmedicationschedule',
	// 	name: 'createmedicationschedule',
	// 	component: () => import('../pages/medications/createMedicationSchedule'),
	// },
];
