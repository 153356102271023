import Axios from 'axios';
import router from '../router';
import {
	API_URL,
	ENTRIES,
	GOALS,
	MEDICATION_RESPONSES,
	MEDICATION_SCHEDULES,
	MEDICATIONS,
	PATIENT_TOKEN,
	PATIENTS,
	RESEARCHER_TOKEN,
	RESEARCHERS,
	STUDIES,
	TIMES,
	FORGOT_PASSWORD,
	PASSWORD_RESET,
	GROUPS
} from '@/store/APIVars';
import { apiCall } from '@/store/apiCall';

const authenticated = {
	state: () => ({
		user: undefined,
		token: localStorage.getItem('auth-token') || null,
		status: false,
		loading: false,
		message: '',
		loggedIn: Boolean(localStorage.getItem('auth-token')),
		userType: null,
		patientGroup: null
	}),
	getters: {
		getMessage: (state) => state.message,
		getUser: (state) => state.user,
		getAuthToken: (state) => state.token,
		getLoading: (state) => state.loading,
		getLoggedIn: (state) => state.loggedIn,
		getUserType: (state) => state.userType,
		isPatient: (state) => state.userType === 'patient',
		isResearcher: (state) => state.userType === 'researcher',
		hasPermissionActionplans: (state) => Boolean(state.patientGroup?.['actionplans']),
		hasPermissionDailyQuestions: (state) => Boolean(state.patientGroup?.['daily_questions']),
		hasPermissionDeviceMeasurements: (state) => Boolean(state.patientGroup?.['device_measurements']),
		hasPermissionGoalTracking: (state) => Boolean(state.patientGroup?.['goal_tracking']),
		hasPermissionGroupProfile: (state) => Boolean(state.patientGroup?.['group_profile']),
		hasPermissionMedications: (state) => Boolean(state.patientGroup?.['medications']),
		hasPermissionProfile: (state) => Boolean(state.patientGroup?.['profile']),
		hasPermissionQuizzes: (state) => Boolean(state.patientGroup?.['quizzes'])
	},
	mutations: {
		// once the token comes back, log it and set it to cookies
		logUser(state, payload) {
		// payload gonna come back as undefined if the email or password is incorrect
			state.loading = true;
			// state.user = payload
			if (payload !== undefined) {
				const { response, success } = payload?.data;
				state.token = response;
				state.status = success;
				state.message = 'Successfully logged in';
				// console.log('Logged in!', state);
				localStorage.setItem('auth-token', response);
				state.loggedIn = true;
				state.loading = false;
			}
			else {
				state.message = 'Either email or password is incorrect, please try again';
			}
		},
		setUser(state, payload) {
			state.user = payload;
		},
		setUserType(state, payload) {
			state.userType = payload;
		},
		setPatientGroup(state, payload) {
			state.patientGroup = payload;
		},
		logUserOut(state) {
			localStorage.removeItem('auth-token');
			state.token = null;
			state.user = null;
			state.loggedIn = false;
			state.userType = null;

			router.push({name: 'researcherLogin'});
		},
	},
	actions: {
		// send the form data over here and hit the API
		async logResearcherIn({ commit, dispatch, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback, user } = {}) {
			await apiCall(
				() => Axios.post(API_URL + RESEARCHER_TOKEN, user),
				setState ? setState : (data) => { console.log(data); commit('logUser', data); },
				modifier ? modifier : (response) => response,
				callback,
				errorCondition,
				errorCallback ? errorCallback : () => { console.log('wasn\'t a researcher'); }
			).then(() => { dispatch('getCurrentResearcher'); });
		},
		async logPatientIn({ commit, dispatch, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback, user } = {}) {
			await apiCall(
				() => Axios.post(API_URL + PATIENT_TOKEN, user),
				setState ? setState : (data) => { commit('logUser', data); },
				modifier ? modifier : (response) => response,
				callback,
				errorCondition,
				errorCallback ? errorCallback : () => { console.log('wasn\'t a researcher'); }
			).then(() => { dispatch('getCurrentPatient'); });
		},
		async passwordResetRequest({ commit, dispatch, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback, email } = {}) {
			await apiCall(
				() => Axios.post(API_URL + FORGOT_PASSWORD, { email: email }),
				setState ? setState : () => {},
				modifier,
				callback,
				errorCondition,
				errorCallback ? errorCallback : () => { console.log('submitting password reset request failed'); }
			);
		},
		async resetPassword({ commit, dispatch, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback, credentials } = {}) {
			await apiCall(
				() => Axios.post(API_URL + PASSWORD_RESET, credentials),
				setState ? setState : () => {},
				modifier,
				callback,
				errorCondition,
				errorCallback ? errorCallback : () => { console.log('submitting password reset request failed'); }
			);
		},
		async inviteResearcher({ commit, dispatch, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback, email } = {}) {
			await apiCall(
				() => Axios.post(API_URL + RESEARCHERS + '/invite', email, { headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}}),
				setState ? setState : () => {},
				modifier,
				callback,
				errorCondition,
				errorCallback ? errorCallback : () => { console.log('submitting researcher invite failed'); }
			);
		},
		/*async logUserIn({ commit, dispatch }, info) {
			const { email, password, device_name, userType } = info;
			const tokenType = userType === 'researcher' ? RESEARCHER_TOKEN : PATIENT_TOKEN;
			const username = email;
			let data = null;
			if (userType === 'researcher'){
				console.log('trying to log in');
				data = await Axios.post(
					API_URL + tokenType,
					{
						email,
						password,
						device_name,
					}
				).catch((err) => console.error(err));
			}
			else {
				data = await Axios.post(
					API_URL + tokenType,
					{
						username,
						password
					}
				).catch((err) => console.error(err));
			}

			commit('logUser', data);
			if (userType === 'researcher')
				dispatch('getCurrentResearcher');
			else
				dispatch('getCurrentPatient');
		},*/
		async logout({ commit, rootGetters }) {
			await apiCall(
				() => Axios.post(API_URL + 'signOut', undefined, {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					}
				}),
				undefined,
				undefined,
				() => { commit('logUserOut'); },
				undefined,
				() => { // even if this request fails for some reason, still commit logUserOut
					console.log('failed to sign out correctly');
					commit('logUserOut');
				}
			);
		},
		async getResearcherTokens({ rootGetters }, { setState, modifier, callback, errorCondition, errorCallback } = {}) {
			await apiCall(
				() => Axios.post(API_URL + 'getTokens', undefined, {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState ? setState : (data) => {
					console.log(data);
				},
				modifier,
				callback,
				errorCondition,
				errorCallback ? errorCallback : () => { console.log('failed to get token list!'); }
			);
		},
		async fullLogout({ commit, rootGetters }, { pass, setState, modifier, callback, errorCondition, errorCallback } = {}) {
			await apiCall(
				() => Axios.post(API_URL + 'fullSignOut', {
					'password': pass
				}, {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					}
				}),
				setState ? setState : () => { commit('logUserOut'); },
				modifier,
				callback,
				errorCondition,
				errorCallback ? errorCallback : () => {
					console.log('failed to full sign out correctly');
				}
			);
		},
		async registerUser({ getters, rootGetters }, info) {
			console.log('INFO');
			console.log(info);
			const {email, password, name } = info;
			await Axios.post(
				API_URL + RESEARCHERS,
				{
					email,
					password,
					name: name,
				},
				{
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}
			).catch((err) => console.error(err));
			// const computer = 'computer';
		},
		async createMedicationScheduleResponse({getters, rootGetters}, data) {
			await Axios.post(`${API_URL}${PATIENTS}/${MEDICATION_RESPONSES}`, data, {
				headers: {
					Authorization: `Bearer ${rootGetters.getAuthToken}`
				},
			}).catch(e => console.error(e));
		},
		async createPatientMedicationSchedule({getters, rootGetters}, schedule) {
			await Axios.post(`${API_URL}${MEDICATION_SCHEDULES}`, schedule, {
				headers: {
					Authorization: `Bearer ${rootGetters.getAuthToken}`
				},
			}).catch(e => console.error(e));
		},
		/*async getCurrentPatient({ getters, commit, rootGetters }) {
			const response = await Axios.get(`${API_URL}${PATIENTS}/patient`, {
				headers: {
					Authorization: `Bearer ${rootGetters.getAuthToken}`
				}
			}).catch(() => { console.log('wasn\'t a patient'); });
			const userData = response.data.response;
			commit('setUser', userData);
			commit('setUserType', 'patient');
		},
		async getCurrentResearcher({ commit, getters, rootGetters }) {
			const response = await Axios.get(`${API_URL}${RESEARCHERS}/researcher`, {
				headers: {
					Authorization: `Bearer ${rootGetters.getAuthToken}`
				}
			}).catch(() => { console.log('wasn\'t a researcher'); });
			const userData = response.data;
			commit('setUser', userData);
			commit('setUserType', 'researcher');
		},*/
		async getCurrentPatient({ getters, commit, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback } = {}) {
			await apiCall(
				() => Axios.get(API_URL + PATIENTS + '/patient', {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState ? setState : (data) => {
					commit('setUser', data);
					commit('setUserType', 'patient');
				},
				modifier,
				callback,
				errorCondition,
				errorCallback ? errorCallback : () => { console.log('wasn\'t a patient'); }
			);
		},

		async getCurrentResearcher({ commit, getters, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback } = {}) {
			await apiCall(
				() => Axios.get(API_URL + RESEARCHERS + '/researcher', {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState ? setState : (data) => {
					console.log(data);
					commit('setUser', data);
					commit('setUserType', 'researcher');
				},
				modifier,
				callback,
				errorCondition,
				errorCallback ? errorCallback : () => { console.log('wasn\'t a researcher'); }
			);
		},
		async researcherOrPatient({ getters, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback } = {}) {
			await apiCall(
				() => Axios.get(API_URL + 'which', { headers: { Authorization: 'Bearer ' + rootGetters.getAuthToken } }),
				setState,
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async getCurrentResearcherStudies({ commit, getters, rootGetters }){
			await Axios.get(API_URL + RESEARCHERS + STUDIES, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('studies/setStudies', data.data.response);
					return data.data.response;
				})
				.catch((err) => console.error(err));
		},
		async getCurrentResearcherPatients({ commit, getters, rootGetters }){
			await Axios.get(API_URL + RESEARCHERS + PATIENTS, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('patients/setPatients', data.data.response);
					return data.data.response;
				})
				.catch((err) => console.error(err));
		},
		async getCurrentPatientStudies({ commit, getters, rootGetters }){
			await Axios.get(API_URL + PATIENTS + STUDIES, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('studies/setStudies', data.data.response);
					return data.data.response;
				})
				.catch((err) => console.error(err));
		},
		async getCurrentPatientGroup({ commit, getters, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback } = {}) {
			await apiCall(
				() => Axios.get(API_URL + PATIENTS + '/' + GROUPS, { headers: { Authorization: 'Bearer ' + rootGetters.getAuthToken } }),
				setState ? setState : (data) => commit('setPatientGroup', data),
				modifier,
				callback ? callback : (data) => console.log(data),
				errorCondition,
				errorCallback
			);
		},
		async getCurrentPatientGoals({ commit, getters, rootGetters }){
			await Axios.get(API_URL + PATIENTS + '/' + GOALS, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('goals/setGoals', data.data.response);
					return data.data.response;
				})
				.catch((err) => console.error(err));
		},
		async getCurrentPatientEntries({ commit, getters, rootGetters }){
			await Axios.get(API_URL + PATIENTS + ENTRIES, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('entries/setEntries', data.data.response);
					return data.data.response;
				})
				.catch((err) => console.error(err));
		},

		// gets the current patient's responses for a particular medication schedule.
		async getCurrentPatientResponsesByMedicationSchedule({ commit, getters, rootGetters }, medication_schedule_id){
			await Axios.get(API_URL + PATIENTS + `/` + MEDICATION_SCHEDULES + `/` + medication_schedule_id + `/` + MEDICATION_RESPONSES, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('medications/setMedResponses', data.data.response);
					return data.data.response;
				})
				.catch((err) => console.error(err));
		},

		async getCurrentPatientMedicationScheduleResponses({commit, getters, rootGetters}, { setState, modifier, callback, errorCondition, errorCallback } = {}) {
			await apiCall(
				() => Axios.get(`${API_URL}${PATIENTS}/${MEDICATION_SCHEDULES}/${MEDICATION_RESPONSES}`, {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState ? setState : (data) => { commit(`medications/setMedResponses`, data);},
				modifier,
				callback,
				errorCondition,
				errorCallback ? errorCallback : (err) => console.error(err)
			);
		},

		async updateCurrentPatient({ commit, getters, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback, formData } = {}) {
			await apiCall(
				() => Axios.put(API_URL + PATIENTS + '/patient', formData, {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState ? setState : (data) => { commit('patients/setPatient', data.response); },
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async getCurrentResearcherStudies({ commit, getters, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback } = {}) {
			await apiCall(
				() => Axios.get(API_URL + RESEARCHERS  + '/' + STUDIES, {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState ? setState : (data) => { commit('studies/setStudies', data.response); },
				modifier,
				callback,
				errorCondition,
				errorCallback ? errorCallback : (err) => console.error(err)
			);
		},
		async getCurrentResearcherPatients({ commit, getters, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback } = {}) {
			await apiCall(
				() => Axios.get(API_URL + RESEARCHERS  + '/' + PATIENTS, {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState ? setState : (data) => { commit('patients/setPatients', data.response); },
				modifier,
				callback,
				errorCondition,
				errorCallback ? errorCallback : (err) => console.error(err)
			);
		},
		async getCurrentPatientStudies({ commit, getters, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback } = {}) {
			await apiCall(
				() => Axios.get(API_URL + PATIENTS  + '/' + STUDIES, {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState ? setState : (data) => { commit('studies/setCurrentStudy', data[0]); },
				modifier,
				callback,
				errorCondition,
				errorCallback ? errorCallback : (err) => console.error(err)
			);
		},
		async getCurrentPatientGoals({ commit, getters, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback } = {}) {
			await apiCall(
				() => Axios.get(API_URL + PATIENTS  + '/' + GOALS, {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState ? setState : (data) => { commit('goals/setGoals', data.response); },
				modifier,
				callback,
				errorCondition,
				errorCallback ? errorCallback : (err) => console.error(err)
			);
		},
		async getCurrentPatientGoal({ commit, getters, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback, goalId } = {}) {
			await apiCall(
				() => Axios.get(API_URL + PATIENTS  + '/' + GOALS + '/' + goalId, {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState ? setState : (data) => { commit('goals/setGoal', data.response); },
				modifier,
				callback,
				errorCondition,
				errorCallback ? errorCallback : (err) => console.error(err)
			);
		},
		async getCurrentPatientEntries({ commit, getters, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback } = {}) {
			await apiCall(
				() => Axios.get(API_URL + PATIENTS  + '/' + ENTRIES, {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState ? setState : (data) => { commit('entries/setEntries', data.response); },
				modifier,
				callback,
				errorCondition,
				errorCallback ? errorCallback : (err) => console.error(err)
			);
		},
		async getCurrentPatientEntriesByGoal({ commit, getters, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback, goalId } = {}) {
			await apiCall(
				() => Axios.get(API_URL + PATIENTS  + '/' + GOALS + '/' + goalId + '/' + ENTRIES, {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState ? setState : (data) => { commit('entries/setEntries', data.response); },
				modifier,
				callback,
				errorCondition,
				errorCallback ? errorCallback : (err) => console.error(err)
			);
		},
		async getCurrentPatientGoalScheduleEntries({ commit, getters, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback, goalId } = {}) {
			await apiCall(
				() => Axios.get(API_URL + PATIENTS + '/' + GOALS + `/${goalId}/schedule/` + ENTRIES, {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState ? setState : (data) => { commit('goals/setGoal', data); },
				modifier,
				callback,
				errorCondition,
				errorCallback ? errorCallback : (err) => console.error(err)
			);
		},
		async getCurrentPatientMedicationsSchedulesAndTimes({ commit, getters, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback } = {}) {
			await apiCall(
				() => Axios.get(API_URL + PATIENTS  + '/' + MEDICATIONS + '/' + MEDICATION_SCHEDULES + '/' + TIMES, {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState ? setState : (data) => { commit('medications/setMedications', data); },
				modifier,
				callback,
				errorCondition,
				errorCallback ? errorCallback : (err) => console.error(err)
			);
		},
		async getCurrentPatientMedicationResponses({ commit, getters, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback } = {}) {
			await apiCall(
				() => Axios.get(API_URL + PATIENTS  + '/' + MEDICATION_RESPONSES, {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState ? setState : (data) => { commit('medications/setMedResponses', data.response); },
				modifier,
				callback,
				errorCondition,
				errorCallback ? errorCallback : (err) => console.error(err)
			);
		},
		async getCurrentPatientMedicationScheduleAndDates({commit, getters, rootGetters}, { setState, modifier, callback, errorCondition, errorCallback, medication_schedule_id } = {}) {
			await apiCall(
				() => Axios.get(API_URL + PATIENTS  + '/' + MEDICATION_SCHEDULES + '/' + medication_schedule_id + '/schedule', {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState ? setState : (data) => { commit('medications/setCurrentMedSchedule', data.response); },
				modifier,
				callback,
				errorCondition,
				errorCallback ? errorCallback : (err) => console.error(err)
			);
		},

		async getCurrentPatientMedicationScheduleWithResponsePopulatedDates({commit, rootGetters}, {medication_schedule_id} = {}) {
			const payload = await Axios.get(
				`${API_URL + PATIENTS}/${MEDICATION_SCHEDULES}/${medication_schedule_id}/schedule/${MEDICATION_RESPONSES}`,
				{headers: { Authorization: 'Bearer ' + rootGetters.getAuthToken}}
			);

			if (payload)
				commit('patients/setPatientMedications', payload.data.response);
		},
	}
};

export default authenticated;
