
export default [
	// {
	// 	path: '/studies',
	// 	name: 'allstudies',
	// 	component: () => import('../pages/studies/studylist')
	// },
	{ // researchers only
		path: '/researchers/:researcherId/studies',
		name: 'indexStudy',
		component: () => import('../pages/studies/indexStudies'),
		props: (route) => {
			return { researcherId: +route.params.researcherId };
		}
	},
	{ // researchers only
		path: '/studies/create',
		name: 'createStudy',
		component: () => import('../pages/studies/createStudy')
	},
	{ // researchers only
		path: '/studies/:studyId',
		name: 'showStudy',
		component: () => import('../pages/studies/showStudy'),
		props: (route) => {
			return { studyId: +route.params.studyId };
		}
	},
	{ // researchers only
		path: '/studies/:studyId/edit',
		name: 'editStudy',
		component: () => import('../pages/studies/editStudy'),
		props: (route) => {
			return { studyId: +route.params.studyId };
		}
	},
	//{ // researcher only
	//path: '/studies/settings',
	//name: 'studiessettings',
	//	component: () => import('../pages/studies/studySetting.vue')
	//},
	/*{
		path: '/studies/manage',
		name: 'managestudies',
		component: () => import('../pages/studies/manageStudies')
	},*/
	/*{
		path: '/studies/delete/:studyId',
		name: 'deletestudy',
		// component: () => import('../pages/studies/deletestudy'),
		props: (route) => {
			return {
				studyId: route.params.studyId
			};
		}
	},*/
	/*{
		path: '/studies/messageallpatients',
		name: 'messageallpatients',
		component: () => import('../pages/messageallpatients')
	},*/
	/*{
		path: '/studies/createwizard',
		name: 'createstudywizard',
		component: () => import('../pages/unused/createstudywizard')
	}*/
];
