import store from '@/store';
import NavigationGuards from './NavigationGuards';

export default [
	{ // patients only
		path: '/profile/goals/:goalId/entries',
		name: 'patientIndexEntry',
		component: () => import('../pages/entries/indexEntry'),
		props: (route) => {
			return {
				patientId: store.getters.getUser.id,
				goalId: +route.params.goalId
			};
		},
		beforeEnter: NavigationGuards.goalsPatientGuard
	},
	{ // researchers only
		path: '/studies/:studyId/groups/:groupId/patients/:patientId/goals/:goalId/entries',
		name: 'indexEntry',
		component: () => import('../pages/entries/indexEntry'),
		props: (route) => {
			return {
				studyId: +route.params.studyId,
				groupId: +route.params.groupId,
				patientId: +route.params.patientId,
				goalId: +route.params.goalId
			};
		}
	},
	{ // patients only
		path: '/profile/goals/:goalId/entries/create',
		name: 'patientCreateEntry',
		component: () => import('../pages/entries/modifyEntry'),
		props: (route) => {
			return {
				edit: false,
				patientId: store.getters.getUser.id,
				goalId: +route.params.goalId
			};
		},
		beforeEnter: NavigationGuards.goalsPatientGuard
	},
	{ // patients only
		path: '/profile/goals/:goalId/entries/:entryId',
		name: 'patientShowEntry',
		component: () => import('../pages/entries/showEntry'),
		props: (route) => {
			return {
				patientId: store.getters.getUser.id,
				goalId: +route.params.goalId,
				entryId: +route.params.entryId
			};
		},
		beforeEnter: NavigationGuards.goalsPatientGuard
	},
	{ // researchers only
		path: '/studies/:studyId/groups/:groupId/patients/:patientId/goals/:goalId/entries/:entryId',
		name: 'showEntry',
		component: () => import('../pages/entries/showEntry'),
		props: (route) => {
			return {
				studyId: +route.params.studyId,
				groupId: +route.params.groupId,
				patientId: +route.params.patientId,
				goalId: +route.params.goalId,
				entryId: +route.params.entryId
			};
		}
	},
	/*{
		path: '/entries',
		name: 'allentries',
		// component: () => import('../pages/entries/entrylist')
	},
	{
		path: '/entries/:entriesId',
		name: 'showentry',
		// component: () => import('../pages/entries/showentry'),
		props: (route) => {
			return {
				entriesId: route.params.entriesId,
				patientId: route.params.patientId,
				goalId: route.params.goalId
			};
		}
	},*/
];