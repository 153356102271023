import Axios from 'axios';
import {API_URL, PATIENTS, RESEARCHERS, STUDIES} from '@/store/APIVars';
import { apiCall } from '@/store/apiCall';

const researcher = {
	namespaced: true,
	state: () => ({
		researchers: [], 
		researcher: {},
	}),
	getters: {
		getResearchers(state){
			return state.researchers;
		}
	},
	mutations: {
		setResearchers(state, payload){
			state.researchers = payload;
		},
		setResearcher(state, payload) {
			state.researchers = payload;
		},
		addResearcher(state, payload){
			state.researchers.push(payload);
		}
	},
	actions: {
		// API calls
		async getAllResearchers({ commit, getters, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback } = {}) {
			await apiCall(
				() => Axios.get(API_URL + RESEARCHERS, {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState,
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async getResearcher({ commit, getters, rootGetters },  { setState, modifier, callback, errorCondition, errorCallback, researcher_id } = {}) {
			await apiCall(
				() => Axios.get(API_URL + RESEARCHERS+ `/${researcher_id}`, {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState ? setState : (data) => { commit('addResearcher', data.response); },
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async createResearcher({ commit, getters, rootGetters }, { formData, setState, modifier, callback, errorCondition, errorCallback } = {}) {
			return await apiCall(
				() => Axios.post(API_URL + RESEARCHERS, formData, {headers: {Authorization: 'Bearer ' + rootGetters.getAuthToken}}),
				setState,
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async deleteResearcher({ commit, getters, rootGetters }, { researcher_id, setState, modifier, callback, errorCondition, errorCallback } = {}) {
			return await apiCall(
				() => Axios.delete(API_URL + RESEARCHERS + `/${researcher_id}`, {headers: {Authorization: 'Bearer ' + rootGetters.getAuthToken}}),
				setState,
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async editResearcher({ commit, getters, rootGetters }, { formData, setState, modifier, callback, errorCondition, errorCallback } = {}) {
			return await apiCall(
				() => Axios.put(API_URL + RESEARCHERS + `/${formData.id}`, formData, {headers: {Authorization: 'Bearer ' + rootGetters.getAuthToken}}),
				setState,
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async getResearcherStudies({ commit, getters, rootGetters },  { setState, modifier, callback, errorCondition, errorCallback, researcher_id } = {}) {
			await apiCall(
				() => Axios.get(API_URL + RESEARCHERS + `/${researcher_id}` + STUDIES, {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState,// ? setState : (data) => { commit('studies/setStudies', data.data.response); },
				modifier,
				callback,
				errorCondition,
				errorCallback
			);		
		},
		async getResearcherPatients({ commit, getters, rootGetters }, researcher_id) {
			await Axios.get(API_URL + RESEARCHERS + `/${researcher_id}/` + PATIENTS, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('patients/setPatients', data.data.response);
					return data.data.response;
				})
				.catch((err) => console.error(err));
		},

	}
};

export default researcher;
