import store from '@/store';

const actionplansPatientGuard = (to, from, next) => {
	if (store.state.authenticated.patientGroup?.actionplans) next();
	else next(false);
};
const goalsPatientGuard = (to, from, next) => {
	if (store.state.authenticated.patientGroup?.goal_tracking) next();
	else next(false);
};
const groupProfilePatientGuard = (to, from, next) => {
	if (store.state.authenticated.patientGroup?.group_profile) next();
	else next(false);
};
const medicationsPatientGuard = (to, from, next) => {
	if (store.state.authenticated.patientGroup?.medications) next();
	else next(false);
};
const profilePatientGuard = (to, from, next) => {
	if (store.state.authenticated.patientGroup?.profile) next();
	else next(false);
};
const quizzesPatientGuard = (to, from, next) => {
	if (store.state.authenticated.patientGroup?.quizzes) next();
	else next(false);
};
const dailyQuestionsPatientGuard = (to, from, next) => {
	if (store.state.authenticated.patientGroup?.daily_questions) next();
	else next(false);
};

export default {
	actionplansPatientGuard, goalsPatientGuard,
	groupProfilePatientGuard, medicationsPatientGuard,
	profilePatientGuard, quizzesPatientGuard,
	dailyQuestionsPatientGuard
};