
export default [
	/*{
		path: '/signup',
		name: 'signup',
		component: () => import('../pages/users/signup')
	},*/
	{ // researchers only
		path: '/researchers',
		name: 'indexResearcher',
		component: () => import('../pages/researchers/IndexResearcher'),
	},
	{ // admin reserachers only
		path: '/researchers/invite',
		name: 'inviteResearcher',
		component: () => import('../pages/researchers/inviteResearcher'),
	},
	{ // researchers only
		path: '/researchers/create/:token',
		name: 'createResearcher',
		component: () => import('../pages/researchers/CreateResearcher'),
		props: (route) => {
			return {
				token: route.params.token
			};
		}
	},
	{ // researchers only
		path: '/researchers/:researcherId',
		name: 'showResearcher',
		component: () => import('../pages/researchers/ShowResearcher'),
		props: (route) => {
			return {
				researcherId: +route.params.researcherId
			};
		},
	},
	{ // researchers only
		path: '/researchers/:researcherId/edit',
		name: 'editResearcher',
		component: () => import('../pages/researchers/EditResearcher'),
		props: (route) => {
			return {
				researcherId: +route.params.researcherId
			};
		}
	}


	// { // researchers only
	// 	path: '/researchers',
	// 	name: 'indexResearcher',
	// 	//component: () => import('../pages/users/indexResearchers')
	// },
	// { // researchers only
	// 	path: '/researchers/create',
	// 	name: 'createResearcher',
	// 	//component: () => import('../pages/researchers/createResearcher')
	// },
	// { // researchers only
	// 	path: '/researchers/:researcherId',
	// 	name: 'showResearcher',
	// 	//component: () => import('../pages/researchers/showResearcher'),
	// 	props: (route) => {
	// 		return { researcher_id: +route.params.researcherId };
	// 	}
	// },
	// { // researchers only
	// 	path: '/researchers/:researcherId/edit',
	// 	name: 'editResearcher',
	// 	//component: () => import('../pages/researchers/editResearcher'),
	// 	props: (route) => {
	// 		return { researcher_id: +route.params.researcherId };
	// 	}
	// },
	/*{ // My Studies?
		path: '/user/:userId/studies',
		name: 'userstudies',
		// component: () => import('../pages/users/userstudies'),
		props: (route) => {
			return {
				userId: route.params.userId
			};
		}
	},
	{ // My Patients?
		path: '/user/:userId/patients',
		name: 'userpatients',
		// component: () => import('../pages/users/userpatients'),
		props: (route) => {
			return {
				userId: route.params.userId
			};
		}
	},
	{
		path: '/user/:userId',
		name: 'usershow',
		// component: () => import('../pages/users/usershow'),
		props: (route) => {
			return {
				userId: route.params.userId
			};
		}
	}*/
];
