import Axios from 'axios';
import {API_URL, MEDICATION_SCHEDULES, MEDICATIONS, PATIENTS, TIMES} from '@/store/APIVars';
import { apiCall } from '../apiCall';

const medication = {
	namespaced: true,
	state: () => ({
		currentMedication: null,
		currentSchedule: null,
		medications: [],
		medicationSchedules: [],
		medicationResponses: [],
		times: [],
	}),
	getters: {
		getMedications(state){
			return state.medications;
		}
	},
	mutations: {
		setMedications(state, payload){
			state.medications = payload;
		},
		addMedication(state, payload){
			// state.medications.push(payload);
			state.currentMedication = payload;
		},
		setMedSchedules(state, payload){
			state.medicationSchedules = payload;
		},
		addMedSchedule(state, payload){
			state.medicationSchedules.push(payload);
		},
		setCurrentMedSchedule(state, payload) {
			state.currentSchedule = payload;
		},
		setMedResponses(state, payload){
			state.medicationResponses = payload;
		},
		addMedResponse(state, payload){
			state.medicationResponses.push(payload);
		},
		setTimes(state, payload){
			state.times = payload;
		},
		addTimes(state, payload){
			state.times.push(payload);
		},
	},
	actions: {
		// API calls
		async getAllMedications({ commit, getters, rootGetters }) {
			await Axios.get(API_URL + MEDICATIONS, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					return new Promise((resolve, reject) => {
						setTimeout(() => {
							commit('setMedications', data.data.response);
							resolve();
						}, 1000);
					});
				})
				.catch((err) => console.error(err));
		},

		async getMedication({ commit, getters, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback, medication_id } = {}) {
			await apiCall(
				() => Axios.get(`${API_URL}${MEDICATIONS}/${medication_id}`, {
					headers: {
						Authorization: `Bearer ${rootGetters.getAuthToken}`
					},
				}),
				setState ? setState : (data) => { commit('addMedication', data); },
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},

		async createMedication({ commit, getters, rootGetters }, payload){
			await Axios.post(API_URL + MEDICATIONS,
				payload,
				{
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}
			)
				.then((data) => {
					commit('addMedication', data.data.response);
				})
				.catch((err) => console.error(err));
		},

		async getMedicationMedicationSchedules({ commit, getters, rootGetters }, medication_id) {
			await Axios.get(API_URL + MEDICATIONS + `/${medication_id}/` + MEDICATION_SCHEDULES, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					console.log('Fetched med: ', data.data.response);
					commit('setMedSchedules', data.data.response);
				})
				.catch((err) => console.error(err));
		},
		async getMedicationPatients({ commit, getters, rootGetters }, medication_id) {
			await Axios.get(API_URL + MEDICATIONS + `/${medication_id}/` + PATIENTS, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					console.log('Fetched patient: ', data.data.response);
					commit('patients/setPatients', data.data.response);
				})
				.catch((err) => console.error(err));
		},
		async getMedicationSchedules({ commit, getters, rootGetters }) { // all schedules
			await Axios.get(API_URL + MEDICATION_SCHEDULES, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					return new Promise((resolve, reject) => {
						setTimeout(() => {
							commit('setMedSchedules', data.data.response);
							resolve();
						}, 1000);
					});
				})
				.catch((err) => console.error(err));
		},

		async getMedicationSchedule({ commit, getters, rootGetters }, medicationSchedule_id) { // one schedule
			await Axios.get(API_URL + MEDICATION_SCHEDULES + `/${medicationSchedule_id}`, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					return new Promise((resolve, reject) => {
						setTimeout(() => {
							commit('setCurrentMedSchedule', data.data.response);
							resolve();
						}, 1000);
					});
				})
				.catch((err) => console.error(err));
		},

		async editMedicationSchedule({ commit, getters, rootGetters }, formData) {
			await Axios.put(API_URL + MEDICATION_SCHEDULES + '/' + formData.id, formData, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				},
			}).then(data => {
				return new Promise((resolve, reject) => {
					setTimeout(() => {
						commit('setCurrentMedSchedule', data.data.response);
						resolve();
					}, 1000);
				});
			}).catch(err => console.error(err));
		},
		async editMedication({commit, getters, rootGetters}, {medication, medication_id} = {}) {
			await Axios.put(API_URL + MEDICATIONS + `/${medication_id}`, medication, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				},
			}).then(data => {
				return new Promise((resolve, reject) => {
					setTimeout(() => {
						commit(`addMedication`, data.data.response);
						resolve();
					}, 1000);
				}).catch(e => console.error(e));
			});
		},
		async createMedicationSchedule({ commit, getters, rootGetters }, payload) {
			await Axios.post(API_URL + MEDICATION_SCHEDULES,
				payload,
				{
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				})
				.then((data) => {
					return new Promise((resolve, reject) => {
						setTimeout(() => {
							commit('addMedSchedule', data.data.response);
							resolve();
						}, 1000);
					});
				})
				.catch((err) => console.error(err));
		},
		async updateMedicationSchedule({ commit, getters, rootGetters }, payload) { // does not work.
			await Axios.put(API_URL + MEDICATION_SCHEDULES + `/${payload.id}/`,
				payload,
				{
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				})
				.then((data) => {
					commit('addMedSchedule', data.data.response);
				})
				.catch((err) => console.error(err));
		},
		async getMedicationSchedulesMedications({ commit, getters, rootGetters }, medication_schedule_id) {
			await Axios.get(API_URL + MEDICATION_SCHEDULES + `/${medication_schedule_id}/` + MEDICATIONS, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('setMedications', data.data.response);
				})
				.catch((err) => console.error(err));
		},
		async getMedicationSchedulesPatients({ commit, getters, rootGetters }, medication_schedule_id) {
			await Axios.get(API_URL + MEDICATION_SCHEDULES + `/${medication_schedule_id}/` + PATIENTS, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('patients/setPatients', data.data.response);
				})
				.catch((err) => console.error(err));
		},
		async getMedicationSchedulesTimes({ commit, getters, rootGetters }, medication_schedule_id) {
			await Axios.get(API_URL + MEDICATION_SCHEDULES + `/${medication_schedule_id}/` + TIMES, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('setTimes', data.data.response);
				})
				.catch((err) => console.error(err));
		},
		async getAllTimes({ commit, getters, rootGetters }) {
			await Axios.get(API_URL + TIMES, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('setTimes', data.data.response);
				})
				.catch((err) => console.error(err));
		},
		async getTime({ commit, getters, rootGetters }, time_id) {
			await Axios.get(API_URL + TIMES + `/${time_id}`, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('setTimes', data.data.response);
				})
				.catch((err) => console.error(err));
		},
		async getTimeMedicationSchedules({ commit, getters, rootGetters }, time_id) {
			await Axios.get(API_URL + TIMES + `/${time_id}/` + MEDICATION_SCHEDULES, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('setMedSchedules', data.data.response);
				})
				.catch((err) => console.error(err));
		},
	},
};

export default medication;
