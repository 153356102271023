import Vue from 'vue';
import VueRouter from 'vue-router';
import studies from '@/router/studies';
import tags from '@/router/tags';
import groups from '@/router/groups';
import medications from '@/router/medications';
import medicationSchedules from '@/router/medication_schedule';
import quizzes from '@/router/quizzes';
import questions from '@/router/questions';
import patients from '@/router/patients';
import goals from '@/router/goals';
import entries from '@/router/entries';
import store from '../store';
import actionPlanning from '@/router/actionPlanning';
import researcher from '@/router/researcher';
import authentication from '@/router/authentication';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('../pages/Home.vue'),
	},
	/*{
		path: '/readbedtimedata',
		name: 'readbedtimedata',
		component: () => import('../pages/unused/ReadBedTimeData'),
	},*/
	{
		path: '/apitesting',
		name: 'apitesting',
		component: () => import('../pages/apitesting')
	},
];


// add route modules here:
routes.push(...authentication, ...researcher, ...studies, ...tags, ...groups, ...patients, ...goals, ...entries, ...medications, ...medicationSchedules, ...quizzes, ...questions, ...actionPlanning);

// Catch all route (404)
routes.push({
	path: '*',
	name: 'PageNotFound',
	component: () => import('../pages/404'),
});

const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior (to, from, savedPosition) { // controls navigation behavior
		if (savedPosition)
		  return savedPosition; // if savedPosition is filled, then navigate there
		else if (to.hash)
		  return { selector: to.hash }; // simulated scroll-to-anchor behavior
		else
		  return {x: 0, y: 0}; // else scroll to top
	}
});

const createResearcherRoute = researcher.find(r => r.name == 'createResearcher');
if (createResearcherRoute == -1) console.error('Cannot find Create Researcher Route!!!!');
const guestRouteNames = authentication.map((r) => r.name).concat([createResearcherRoute?.name]);
console.log('guest route names', guestRouteNames);
function checkIfRouteIsGuestRoute(to) {
	for (let i = 0; i < guestRouteNames.length; i++)
		if (to.name == guestRouteNames[i])
			return true;
	return false;
}

router.beforeEach((to, from, next) => {
	console.log(to, from, next);
	const loggedIn = store.getters.getLoggedIn;
	if (loggedIn && !store.getters.isResearcher && !store.getters.isPatient) { // userType not set right, but auth-token is there.
		const afterSuccess = () => {
			if (checkIfRouteIsGuestRoute(to)) {
				router.push({name: 'home'});
			}
			else {
				next();
			}
		};
		store.dispatch('researcherOrPatient', {
			errorCallback: () => {
				store.dispatch('logout');
			},
			callback: (result) => {
				if (result == 'researcher')
					store.dispatch('getCurrentResearcher', { callback: afterSuccess });
				else // patient
					Promise.allSettled([
						store.dispatch('getCurrentPatient'),
						store.dispatch('getCurrentPatientGroup')
					]).then(afterSuccess);
			}
		});
	}
	else {
		if (!loggedIn && checkIfRouteIsGuestRoute(to)) {
			next();
		}
		else if (!loggedIn && !checkIfRouteIsGuestRoute(to)){
			router.push({name: 'researcherLogin'});
		}
		else if (loggedIn && checkIfRouteIsGuestRoute(to)){
			router.push({name: 'home'});
		}
		else {
			next();
		}
	}
});

export default router;
