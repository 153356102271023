import store from '@/store';
import NavigationGuards from './NavigationGuards';

export default [
	// {
	// 	path: '/goals',
	// 	name: 'allgoals',
	// 	component: () => import('../pages/goals/goallist')
	// },
	{ // patients only
		path: '/profile/goals',
		name: 'patientIndexGoals',
		component: () => import('../pages/goals/indexGoal'),
		props: (route) => { return { patientId: store.getters.getUser.id }; },
		beforeEnter: NavigationGuards.goalsPatientGuard
	},
	{ // researcher only
		path: '/studies/:studyId/groups/:groupId/patients/:patientId/goals',
		name: 'indexGoals',
		component: () => import('../pages/goals/indexGoal'),
		props: (route) => {
			return {
				studyId: +route.params.studyId,
				groupId: +route.params.groupId,
				patientId: +route.params.patientId
			};
		}
	},
	{ // patients create
		path: '/profile/goals/create',
		name: 'patientCreateGoals',
		component: () => import('../pages/goals/modifyGoal'),
		props: (route) => {
			return {
				patientId: store.getters.getUser.id
			};
		},
		beforeEnter: NavigationGuards.goalsPatientGuard
	},
	{ // patients only
		path: '/profile/goals/:goalId',
		name: 'patientShowGoal',
		component: () => import('../pages/goals/showGoal'),
		props: (route) => {
			return {
				patientId: store.getters.getUser.id,
				goalId: +route.params.goalId
			};
		},
		beforeEnter: NavigationGuards.goalsPatientGuard
	},
	{ // researcher only
		path: '/studies/:studyId/groups/:groupId/patients/:patientId/goals/:goalId',
		name: 'showGoal',
		component: () => import('../pages/goals/showGoal'),
		props: (route) => {
			return {
				studyId: +route.params.studyId,
				groupId: +route.params.groupId,
				patientId: +route.params.patientId,
				goalId: +route.params.goalId
			};
		}
	},
	/*{ // This is currently in router/patients.js  as createGoal
		path: '/goal/set',
		name: 'setgoal',
		// component: () => import('../pages/goals/setgoal')
	},
	{
		path: '/goal/:goalId',
		name: 'showgoal',
		component: () => import('../pages/goals/showGoal'),
		props: (route) => {
			return {
				goalId: route.params.goalId
			};
		}
	},
	{
		path: '/goal/:goalId/entries',
		name: 'goalentries',
		// component: () => import('../pages/goals/goalentries'),
		props: (route) => {
			return {
				goalId: route.params.goalId
			};
		}
	},*/
];
