import store from '../store/index';
import NavigationGuards from './NavigationGuards';

export default [
	{ // researcher only
		path: '/studies/:studyId/patients',
		name: 'indexPatient',
		component: () => import('../pages/patients/indexPatient'),
		props: (route) => { return { studyId: +route.params.studyId }; }
	},
	{ // researcher only
		path: '/studies/:studyId/groups/:groupId/patients',
		name: 'indexPatientByGroup',
		component: () => import('../pages/patients/indexPatient'),
		props: (route) => { return { studyId: +route.params.studyId, groupId: +route.params.groupId }; }
	},
	{ // reearcher only
		path: '/studies/:studyId/patients/create',
		name: 'createPatient',
		component: () => import('../pages/patients/modifyPatient'),
		props: (route) => {
			return {
				edit: false,
				studyId: +route.params.studyId
			};
		}
	},
	{ // researcher only
		path: '/studies/:studyId/groups/:groupId/patients/create',
		name: 'createPatientByGroup',
		component: () => import('../pages/patients/modifyPatient'),
		props: (route) => {
			return {
				edit: false,
				studyId: +route.params.studyId,
				groupId: +route.params.groupId
			};
		}
	},
	{ // patients only
		path: '/profile',
		name: 'patientProfile',
		component: () => import('../pages/patients/showPatient'),
		props: (route) => {
			return {
				patientId: store.getters.getUser.id // guarenteed to be there if it gets to here, but runs before hand
			};
		},
		beforeEnter: NavigationGuards.profilePatientGuard
	},
	{ // researcher only
		path: '/studies/:studyId/groups/:groupId/patients/:patientId',
		name: 'showPatient',
		component: () => import('../pages/patients/showPatient'),
		props: (route) => {
			return {
				studyId: +route.params.studyId,
				groupId: +route.params.groupId,
				patientId: +route.params.patientId
			};
		}
	},
	{ // patients only
		path: '/profile/edit',
		name: 'patientEditPatient',
		component: () => import('../pages/patients/modifyPatient'),
		props: (route) => {
			return {
				edit: true,
				patientId: +store.getters.getUser.id // guarenteed to be there if it gets to here, but runs before hand
			};
		},
	},
	{ // researcher only
		path: '/studies/:studyId/groups/:groupId/patients/:patientId/edit',
		name: 'editPatient',
		component: () => import('../pages/patients/modifyPatient'),
		props: (route) => {
			return {
				edit: true,
				studyId: +route.params.studyId,
				groupId: +route.params.groupId,
				patientId: +route.params.patientId
			};
		}
	},
	/*{
		path: '/patients',
		name: 'indexPatients',
		component: () => import('../pages/patients/indexPatient')
	},
	{
		path: '/patients/create/',
		name: 'createpatient',
		component: () => import('../pages/patients/modifyPatient'),
		props: (route) => {
			return {
				edit: false
			};
		}
	},
	{
		path: '/patients/createEntry',
		name: 'createEntry',
		component: () => import('../pages/entries/createEntry')
	},
	{
		path: '/patients/createGoal',
		name: 'createGoal',
		component: () => import('../pages/goals/createGoal')
	},
	{
		path: '/patients/createResponse',
		name: 'createResponse',
		component: () => import('../pages/medications/createMedicationScheduleResponse')
	},
	{ // only for patients
		path: '/patients/profile',
		name: 'patientProfile',
		component: () => import('../pages/patients/showPatient'),
		props: (route) => {
			return {
				patientId: store.getters.getUser.id // guarenteed to be there if it gets to here, but runs before hand
			};
		},
		beforeEnter: (to, from, next) => {
			if (!store.getters.getUser || !store.getters.isPatient)
				return false;
			next();
		}
	},
	{
		path: '/patients/oldpatientprofile',
		name: 'oldPatientprofile',
		component: () => import('../pages/patients/oldPatientProfile'),
		props: (route) => {
			return {
				studyId: route.params.studyId
			};
		}
	},
	{
		path: '/patients/patientProfile',
		name: 'patientProfile',
		component: () => import('../pages/patients/patientProfile'),
		props: (route) => {
			return {
				studyId: route.params.studyId
			};
		}
	},
	{
		path: '/patients/:patientId',
		name: 'showpatient',
		component: () => import('../pages/patients/showPatient'),
		props: (route) => {
			return {
				patientId: +route.params.patientId
			};
		}
	},
	{
		path: '/patients/:patientId/edit',
		name: 'editpatient',
		component: () => import('../pages/patients/modifyPatient'),
		props: (route) => {
			return {
				edit: true,
				patientId: +route.params.patientId
			};
		}
	},
	{
		path: '/patients/:patientId/entries',
		name: 'patiententries',
		// component: () => import('../pages/patients/patiententries'),
		props: (route) => {
			return {
				patientId: route.params.patientId
			};
		}
	},
	{
		path: '/patients/managePatientMedicationSchedules',
		name: 'managePatientMedicationSchedules',
		component: () => import('../pages/medications/managePatientMedicationSchedules')
	},
	{
		path: '/patients/createResponse/:schedule_id',
		name: 'createResponse',
		component: () => import('../pages/medications/createMedicationScheduleResponse'),
		props: (route) => {
			return {
				schedule_id: route.params.schedule_id
			};
		}
	},
	{
		path: '/patients/:patientId/studies',
		name: 'patientstudies',
		// component: () => import('../pages/patients/patientstudies'),
		props: (route) => {
			return {
				patientId: route.params.patientId
			};
		}
	},*/
];
