import store from '@/store';
import NavigationGuards from './NavigationGuards';

export default [
	{ // researchers only 
		path: '/studies/:studyId/groups',
		name: 'indexGroup',
		component: () => import('../pages/groups/indexGroup'),
		props: (route) => {
			return {
				studyId: +route.params.studyId
			};
		}
	},
	{ // patients only
		path: '/profile/group',
		name: 'patientShowGroup',
		//component: () => import('../pages/groups/showGroup'),
		props: (route) => {
			return {
				patientId: +store.getters.getUser.id,
				groupId: +store.getters.getUser.group_id
			};
		},
		beforeEnter: NavigationGuards.groupProfilePatientGuard
	},
	{ // researchers only
		path: '/studies/:studyId/groups/create',
		name: 'createGroup',
		component: () => import('../pages/groups/modifyGroup'),
		props: (route) => {
			return {
				edit: false,
				studyId: +route.params.studyId,
			};
		}
	},
	{ // researchers only
		path: '/studies/:studyId/groups/:groupId/edit',
		name: 'editGroup',
		component: () => import('../pages/groups/modifyGroup'),
		props: (route) => {
			return {
				edit: true,
				studyId: +route.params.studyId,
				groupId: +route.params.groupId
			};
		}
	},
	{ // researchers only
		path: '/studies/:studyId/groups/:groupId',
		name: 'showGroup',
		component: () => import('../pages/groups/showGroup'),
		props: (route) => {
			return {
				studyId: +route.params.studyId,
				groupId: +route.params.groupId
			};
		}
	}
];