import Vue from 'vue';
import App from './App.vue';

import router from './router';
import store from './store';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { BoostrapVue, IconsPlugin } from 'bootstrap-vue';

import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import VueApexCharts from 'vue-apexcharts';
import VCalendar from 'v-calendar';

import JsonCSV from 'vue-json-csv';

Vue.config.productionTip = false;

import './plugins/bootstrap-vue';

import './assets/custom.scss';

import SubmitResetButtonsComponent from './components/utils/SubmitResetButtons';
import EmptyListComponent from './components/utils/EmptyList';

Vue.use(IconsPlugin);
Vue.use(VueApexCharts);
Vue.use(VCalendar);

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

Vue.prototype.$dayjs = dayjs;

Vue.component('apexchart', VueApexCharts);
Vue.component('multi-select', Multiselect);
Vue.component('downloadCsv', JsonCSV);

Vue.component('submit-reset-buttons', SubmitResetButtonsComponent);
Vue.component('empty-list', EmptyListComponent);

import Loading from 'vue-loading-overlay';
Vue.component('loading', Loading);

new Vue({
	store,
	router,
	render: h => h(App),
}).$mount('#app');
