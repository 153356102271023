import store from '@/store';
import NavigationGuards from './NavigationGuards';

export default [
	{ // patients only
		path: '/profile/medication-schedules',
		name: 'patientIndexMedicationSchedule',
		component: () => import('../pages/medicationSchedules/indexMedicationSchedules'),
		props: (route) => {
			return {
				patientId: store.getters.getUser.id
			};
		},
		beforeEnter: NavigationGuards.medicationsPatientGuard
	},
	{ // researchers only
		path: '/studies/:studyId/groups/:groupId/patients/:patientId/medication-schedules',
		name: 'indexMedicationSchedule',
		component: () => import('../pages/medicationSchedules/indexMedicationSchedules'),
		props: (route) => {
			return {
				studyId: +route.params.studyId,
				groupId: +route.params.groupId,
				patientId: +route.params.patientId
			};
		}
	},
	{ // patients only
		path: '/profile/medication-schedules/create',
		name: 'patientCreateMedicationSchedule',
		component: () => import('../pages/medicationSchedules/createMedicationSchedule'),
		props: (route) => {
			return {
				patientId: store.getters.getUser.id
			};
		},
		beforeEnter: NavigationGuards.medicationsPatientGuard
	},
	{ // researchers only
		path: '/studies/:studyId/groups/:groupId/patients/:patientId/medication-schedules/create',
		name: 'createMedicationSchedule',
		component: () => import('../pages/medicationSchedules/createMedicationSchedule'),
		props: (route) => {
			return {
				studyId: +route.params.studyId,
				groupId: +route.params.groupId,
				patientId: +route.params.patientId,
			};
		}
	},
	{ // researchers only
		path: '/studies/:studyId/groups/:groupId/patients/:patientId/medication-schedules/:medicationScheduleId/modify',
		name: 'modifyMedicationSchedule',
		component: () => import('../pages/medicationSchedules/createMedicationSchedule'),
		props: (route) => {
			return {
				medicationScheduleId: +route.params.medicationScheduleId,
				studyId: +route.params.studyId,
				groupId: +route.params.groupId,
				patientId: +route.params.patientId,
			};
		}
	},
	{ // patients only
		path: '/profile/medication-schedules/:medicationScheduleId',
		name: 'patientViewMedicationSchedule',
		component: () => import('../pages/medicationSchedules/viewMedicationSchedule'),
		props: (route) => {
			return {
				patientId: store.getters.getUser.id,
				medicationScheduleId: +route.params.medicationScheduleId
			};
		},
		beforeEnter: NavigationGuards.medicationsPatientGuard
	},
	{ // researchers only
		path: '/studies/:studyId/groups/:groupId/patients/:patientId/medication-schedules/:medicationScheduleId',
		name: 'viewMedicationSchedule',
		component: () => import('../pages/medicationSchedules/viewMedicationSchedule'),
		props: (route) => {
			return {
				studyId: +route.params.studyId,
				groupId: +route.params.groupId,
				patientId: +route.params.patientId,
				medicationScheduleId: +route.params.medicationScheduleId
			};
		}
	},
	{ // patients only
		path: '/profile/medication-schedules/:medicationScheduleId/respond',
		name: 'patientRespondMedicationSchedule',
		component: () => import('../pages/medicationSchedules/createMedicationScheduleResponse'),
		props: (route) => {
			return {
				medicationScheduleId: +route.params.medicationScheduleId,
				patientId: store.getters.getUser.id
			};
		},
		beforeEnter: NavigationGuards.medicationsPatientGuard
	},
];
