<template>
	<div>
		<div class="text-center">
			<p class="text-center block">No {{ itemsName }} found.</p>
			<b-button
				v-if="displayCreateNewButton"
				variant="secondary"
				:to="createNewButtonTo"
			>
				Create a new {{ itemName ? itemName : itemsName }}
			</b-button>
		</div>
	</div>
</template>
<script>
export default {
	name: 'EmptyList',
	props: {
		itemsName: {
			required: true,
			type: String,
			default: () => 'Items'
		},
		displayCreateNewButton: { // if the button is shown, itemName and createNewButtonTo are expected
			required: false,
			type: Boolean,
			default: () => false
		},
		itemName: {
			required: false,
			type: String
		},
		createNewButtonTo: {
			required: false,
			type: Object
		}
	},
	mounted() {
		if (!((this.displayCreateNewButton && this.itemName && this.createNewButtonTo) || !this.displayCreateNewButton))
			console.error('empty-list is missing props! If display-create-new-button is true, then item-name and create-new-button-to must also be set.');
	}
};
</script>