
export default [
	{
		path: '/researcherLogin',
		name: 'researcherLogin',
		component: () => import('../pages/authentication/researcherLogin')
	},
	{
		path: '/patientLogin',
		name: 'patientLogin',
		component: () => import('../pages/authentication/patientLogin')
	},
	{
		path: '/forgotPassword',
		name: 'forgotPassword',
		component: () => import('../pages/authentication/forgotPasswordRequest')
	},
	{
		path: '/passwordReset/:token',
		name: 'passwordReset',
		component: () => import('../pages/authentication/passwordReset'),
		props: (route) => {
			return { token: route.params.token };
		}
	}
];
