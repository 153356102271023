import Vue from 'vue';
import Vuex from 'vuex';
import authenticated from './authenticated';
import form from './form';
import study from '@/store/modules/studyModule';
import patient from '@/store/modules/patientModule';
import medication from '@/store/modules/medicationModule';
import quiz from '@/store/modules/quizModule';
import goal from '@/store/modules/goalModule';
import entry from '@/store/modules/entryModule';
import researcher from '@/store/modules/researcherModule';
import actionPlanning from '@/store/modules/actionPlanningModule';
import group from '@/store/modules/groupModule';
import tag from '@/store/modules/tagModule';
import dailyQuestion from '@/store/modules/dailyQuestionModule';
import measurement from '@/store/modules/measurementModule';

import reactNativeApp from '@/store/modules/reactNativeAppModule';

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {},
	getters: {},
	mutations: {},
	actions: {},
	modules: {
		authenticated: authenticated,
		form: form,
		studies: study,
		patients: patient,
		medications: medication,
		quizzes: quiz,
		goals: goal,
		entries: entry,
		researchers: researcher,
		actionPlanning: actionPlanning,
		groups: group,
		tags: tag,
		dailyQuestions: dailyQuestion,
		reactNativeApp: reactNativeApp,
		measurements: measurement
	}
});

if (module.hot) {
	module.hot.accept([
		'./authenticated',
		'./form',
		'./modules/studyModule',
		'./modules/patientModule',
		'./modules/medicationModule',
		'./modules/quizModule',
		'./modules/goalModule',
		'./modules/entryModule',
		'./modules/researcherModule',
		'./modules/actionPlanningModule',
		'./modules/groupModule',
		'./modules/tagModule',
		'./modules/dailyQuestionModule',
		'./modules/measurementModule',
		'./modules/reactNativeAppModule',
	], () => {
		const authenticatedHot = require('./authenticated').default;
		const formHot = require('./form').default;
		const studiesHot = require('./modules/studyModule').default;
		const patientsHot = require('./modules/patientModule').default;
		const medicationsHot = require('./modules/medicationModule').default;
		const quizzesHot = require('./modules/quizModule').default;
		const goalsHot = require('./modules/goalModule').default;
		const entriesHot = require('./modules/entryModule').default;
		const researchersHot = require('./modules/researcherModule').default;
		const actionPlanningHot = require('./modules/actionPlanningModule').default;
		const groupHot = require('./modules/groupModule').default;
		const tagHot = require('./modules/tagModule').default;
		const dailyQuestionHot = require('./modules/dailyQuestionModule').default;
		const measurement = require('./modules/measurementModule').default;
		const reactNativeAppHot = require('./modules/reactNativeAppModule').default;
		store.hotUpdate({
			modules: {
				authenticated: authenticatedHot,
				form: formHot,
				studies: studiesHot,
				patients: patientsHot,
				medications: medicationsHot,
				quizzes: quizzesHot,
				goals: goalsHot,
				entries: entriesHot,
				researchers: researchersHot,
				actionPlanning: actionPlanningHot,
				groups: groupHot,
				tags: tagHot,
				dailyQuestions: dailyQuestionHot,
				measurements: measurement,
				reactNativeApp: reactNativeAppHot
			}
		});
	});
};

export default store;