import Axios from 'axios';
import {API_URL, ENTRIES, GOALS} from '@/store/APIVars';
import { apiCall } from '@/store/apiCall';

const goal = {
	namespaced: true,
	state: () => ({
		goals: [],
		goal: null,
		patientGoals: [],
	}),
	getters: {
		getGoals(state){
			return state.goals;
		},
		getGoal(state){
			return state.goal;
		},
		getCurrentPatientGoals(state) {
			return state.patientGoals;
		}
	},
	mutations: {
		setGoals(state, payload){
			state.goals = payload;
		},
		addGoal(state, payload){
			state.goals.push(payload);
		},
		setGoal(state, payload){
			state.goal = payload;
		},
		setPatientGoals(state, payload) {
			state.patientGoals = payload;
		}
	},
	actions: {
		async loadPatientGoals({ commit, getters, rootGetters }) {
			// Loads current patient's goals into state
			const response = await Axios.get(`${API_URL}patients/goals`,
				{
					headers: {
						Authorization: `Bearer ${rootGetters.getAuthToken}`
					}
				});
			const goals = response.data.response;
			commit('setPatientGoals', goals);
		},
		// API calls
		async getAllGoals({ commit, getters, rootGetters }) {
			await Axios.get(API_URL + GOALS, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('setGoals', data.data.response);
				})
				.catch((err) => console.error(err));
		},
		async getGoal({ commit, getters, rootGetters }, goal_id) {
			await Axios.get(API_URL + GOALS + `/${goal_id}`, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('setGoal', data.data.response);
				})
				.catch((err) => console.error(err));
		},
		async createGoalPatient({ commit, getters, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback, formData } = {}) {
			await apiCall(
				() => Axios.post(API_URL + GOALS, formData, {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState ? setState : (data) => { commit('setGoal', data); },
				modifier,
				callback,
				errorCondition,
				errorCallback ? errorCallback : (err) => console.error(err)
			);
		},
		async createGoalResearcher({ commit, getters, rootGetters }, {patient_id, goal}){
			await Axios.post(API_URL + GOALS,
				goal,
				{
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}
			)
				.then((data) => {
					commit('addGoal', data.data.response);
				})
				.catch((err) => console.error(err));
		},
		async getGoalEntries({ commit, getters, rootGetters }, goal_id) {
			await Axios.get(API_URL + GOALS + `/${goal_id}/` + ENTRIES, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				},
				params: {
					categorized: true
				}
			})
				.then((data) => {
					// console.log(data);
					commit('setGoal', data.data.response);
				})
				.catch((err) => console.error(err));
		}
	},
};

export default goal;
