const API_URL = process.env.VUE_APP_BACKEND_BASE_URI;

const ENTRIES = 'entries';
const GOALS = 'goals';
const MEDICATIONS = 'medications';
const MEDICATION_SCHEDULES = 'medicationSchedules';
const MEDICATION_RESPONSES = 'medicationResponses';
const PATIENTS = 'patients';
const QUIZZES = 'quizzes';
const STUDIES = 'studies';
const RESEARCHER_TOKEN = 'sanctum/token/researcher';
const PATIENT_TOKEN = 'sanctum/token/patient';
const RESEARCHERS = 'researchers';
const TIMES = 'times';
const QUESTION_CATEGORIES = 'tags';
const TAGS = 'tags';
const ANSWERS = 'answers';
const QUESTIONS = 'questions';
const QUIZ_ATTEMPTS = 'quizAttempts';
const QUESTION_RESPONSES = 'quizQuestionResponses';
const GROUPS = 'groups';
const DAILYQUESTION = 'dailyQuestions';
const FORGOT_PASSWORD = 'forgot-password';
const PASSWORD_RESET = 'reset-password';
const MEASUREMENTS = 'measurements';
const WEIGHTS = 'weights';
const DOWNLOADCSV = 'download_csv';

export {
	API_URL, ENTRIES, GOALS, MEDICATIONS, MEDICATION_RESPONSES, MEDICATION_SCHEDULES, PATIENTS, PATIENT_TOKEN, DAILYQUESTION,
	QUIZZES, RESEARCHERS, RESEARCHER_TOKEN, STUDIES, TIMES, QUESTION_CATEGORIES, TAGS, ANSWERS, QUESTIONS, QUIZ_ATTEMPTS,
	QUESTION_RESPONSES, GROUPS, FORGOT_PASSWORD, PASSWORD_RESET, MEASUREMENTS, WEIGHTS, DOWNLOADCSV
};
