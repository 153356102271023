<template>
	<div>
		<div class="d-flex">
			<div v-if="sidebar" style="z-index: 10000000;">
				<b-button class="navButton navButtonExpanded" @click="toggleSidebar">
					<b-icon icon="x"></b-icon></b-button>
			</div>
			<div v-else style="z-index: 10000000;">
				<b-button class="navButton navButtonClosed" @click="toggleSidebar">
					<b-icon icon="list"></b-icon></b-button>
			</div>
		</div>
		<b-sidebar
			id="sidebar"
			text-variant="light"
			v-model="sidebar"
			aria-controls="sidebar"
			aria-expanded="true"
			:no-close-on-route-change="false"
			:no-header-close="true"
			:no-header="true">
			<b-nav vertical pills>
				<b-nav-item :to="{ name: 'home' }" class="header h4 sidebar-item mt-2 text-center">
					<img id="showNoHover" class="mr-2" src="@/assets/emblem.svg"/>
					<img id="showHover" class="mr-2" src="@/assets/emblem-white.svg"/>
					<span class="align-middle">Intervention Platform</span>
				</b-nav-item>
				<div v-if="this.getLoggedIn">
					<div v-if="isResearcher">
						<b-nav-item v-b-toggle="'collapse-studies'" class="sidebar-item">
							<div class="text-left">
								<p class="pl-2 d-inline">Manage Studies</p>
								<span class="when-opened-chevron float-right">
									<b-icon icon="chevron-down"/>
								</span>
								<span class="when-closed-chevron float-right">
									<b-icon icon="chevron-right"/>
								</span>
							</div>
						</b-nav-item>
					</div>
					<b-collapse id="collapse-studies" class=" pl-2 text-left">
						<b-nav-item :to="{ name: 'createStudy' }" class="ml-2 sidebar-item">
							Create a new Study
						</b-nav-item>
						<b-nav-item v-if="user" :to="{ name: 'indexStudy', params: { researcherId: user.id } }" class="ml-2 sidebar-item">
							View Studies
						</b-nav-item>
					</b-collapse>
					<div v-if="!isResearcher && $store.getters.hasPermissionGoalTracking">
						<b-nav-item v-b-toggle="'collapse-goals'" class="sidebar-item">
							<div class="text-left">
								<p class="pl-2 d-inline">Goals</p>
								<span class="when-opened-chevron float-right">
									<b-icon icon="chevron-down"/>
								</span>
								<span class="when-closed-chevron float-right">
									<b-icon icon="chevron-right"/>
								</span>
							</div>
						</b-nav-item>
				    </div>
					<b-collapse id="collapse-goals" class="pl-2 text-left">
						<b-nav-item :to="{ name: 'patientIndexGoals' }" class="ml-2 sidebar-item">
							My Goals
						</b-nav-item>
                        <b-nav-item :to="{ name: 'patientCreateGoals' }" class="ml-2 sidebar-item">
                            Create New Goal
                        </b-nav-item>
					</b-collapse>
                    <div v-if="isResearcher">
						<div>
							<b-nav-item v-b-toggle="'collapse-quizzes-researcher'" class="sidebar-item">
								<div class="text-left">
									<p class="pl-2 d-inline">Scenarios & Questions</p>
									<span class="when-opened-chevron float-right">
										<b-icon icon="chevron-down"/>
									</span>
									<span class="when-closed-chevron float-right">
										<b-icon icon="chevron-right"/>
									</span>
								</div>
							</b-nav-item>
						</div>
						<b-collapse id="collapse-quizzes-researcher" class="pl-2 text-left">
							<b-nav-item :to="{ name: 'indexQuiz' }" class="ml-2 sidebar-item">
								Manage Scenarios
							</b-nav-item>
							<b-nav-item :to="{ name: 'indexQuestion' }" class="ml-2 sidebar-item">
								Manage Questions
							</b-nav-item>
							<b-nav-item :to="{ name: 'createQuiz' }" class="ml-2 sidebar-item">
								Create New Scenario
							</b-nav-item>
							<b-nav-item :to="{ name: 'createQuestion' }" class="ml-2 sidebar-item">
								Create New Question
							</b-nav-item>
                            <b-nav-item :to="{ name: 'reportQuizzes' }" class="ml-2 sidebar-item">
								Scenario Report
							</b-nav-item>
						</b-collapse>
					</div>
					<div v-if="!isResearcher && $store.getters.hasPermissionQuizzes">
						<div>
							<b-nav-item v-b-toggle="'collapse-quizzes-patient'" class="sidebar-item">
								<div class="text-left">
									<p class="pl-2 d-inline">Scenarios</p>
									<span class="when-opened-chevron float-right">
										<b-icon icon="chevron-down"/>
									</span>
									<span class="when-closed-chevron float-right">
										<b-icon icon="chevron-right"/>
									</span>
								</div>
							</b-nav-item>
						</div>
                        <b-collapse id="collapse-quizzes-patient" class="pl-2 text-left">
							<b-nav-item :to="{ name: 'patientIndexQuiz' }" class="ml-2 sidebar-item">
								My Scenarios
							</b-nav-item>
                            <b-nav-item :to="{ name: 'quizDashboard' }" class="ml-2 sidebar-item">
								Scenario Dashboard
							</b-nav-item>
						</b-collapse>
					</div>
					<div v-if="isResearcher">
						<b-nav-item v-b-toggle="'collapse-medications'" class="sidebar-item">
							<div class="text-left">
								<p class="pl-2 d-inline">Medications</p>
								<span class="when-opened-chevron float-right">
									<b-icon icon="chevron-down"/>
								</span>
								<span class="when-closed-chevron float-right">
									<b-icon icon="chevron-right"/>
								</span>
							</div>
						</b-nav-item>
					</div>
					<b-collapse id="collapse-medications" class="pl-2 text-left">
						<b-nav-item  :to="{ name: 'indexMedication' }" class="ml-2 sidebar-item">
							View Medications
						</b-nav-item>
						<b-nav-item :to="{ name: 'createMedication' }" class="ml-2 sidebar-item">
							Create a new Medication
						</b-nav-item>
					</b-collapse>
					<div v-if="!isResearcher && $store.getters.hasPermissionMedications">
						<div>
							<b-nav-item v-b-toggle="'collapse-medications-patient'" class="sidebar-item">
								<div class="text-left">
									<p class="pl-2 d-inline">Medications</p>
									<span class="when-opened-chevron float-right">
										<b-icon icon="chevron-down"/>
									</span>
									<span class="when-closed-chevron float-right">
										<b-icon icon="chevron-right"/>
									</span>
								</div>
							</b-nav-item>
						</div>
					<b-collapse id="collapse-medications-patient" class="pl-2 text-left">
						<b-nav-item :to="{ name: 'patientIndexMedication' }" class="ml-2 sidebar-item">
							My Medications
						</b-nav-item>
						<b-nav-item :to="{ name: 'patientIndexMedicationSchedule' }" class="ml-2 sidebar-item">
							My Medication Schedules
						</b-nav-item>
					</b-collapse>
					</div>
					<div v-if="isResearcher">
						<b-nav-item v-b-toggle="'collapse-tags'" class="sidebar-item">
							<div class="text-left">
								<p class="pl-2 d-inline">Tags</p>
								<span class="when-opened-chevron float-right">
									<b-icon icon="chevron-down"/>
								</span>
								<span class="when-closed-chevron float-right">
									<b-icon icon="chevron-right"/>
								</span>
							</div>
						</b-nav-item>
					</div>
					<b-collapse id="collapse-tags" class="pl-2 text-left">
						<b-nav-item :to="{ name: 'indexTag' }" class="ml-2 sidebar-item">
							Manage Tags
						</b-nav-item>
						<b-nav-item :to="{ name: 'createTag' }" class="ml-2 sidebar-item">
							Create a new Tag
						</b-nav-item>
					</b-collapse>
					<div v-if="isResearcher">
						<b-nav-item v-b-toggle="'collapse-researchers'" class="sidebar-item">
							<div class="text-left">
								<p class="pl-2 d-inline">Researchers</p>
								<span class="when-opened-chevron float-right">
									<b-icon icon="chevron-down"/>
								</span>
								<span class="when-closed-chevron float-right">
									<b-icon icon="chevron-right"/>
								</span>
							</div>
						</b-nav-item>
					</div>
					<div v-if="isResearcher">
						<b-collapse id="collapse-researchers" class=" pl-2 text-left">
							<b-nav-item v-if="user" :to="{ name: 'indexResearcher' }" class="ml-2 sidebar-item">
								All researchers
							</b-nav-item>
							<b-nav-item :to="{ name: 'showResearcher', params: { researcherId: user.id } }" class="ml-2 sidebar-item">
								View profile
							</b-nav-item>
							<b-nav-item :to="{ name: 'inviteResearcher' }" class="ml-2 sidebar-item">
								Invite a new Researcher
							</b-nav-item>
						</b-collapse>
					</div>
					<div v-if="!isResearcher && $store.getters.hasPermissionActionplans">
						<b-nav-item v-b-toggle="'collapse-actionplans'" class="sidebar-item">
							<div class="text-left">
								<p class="pl-2 d-inline">Action Plans</p>
								<span class="when-opened-chevron float-right">
									<b-icon icon="chevron-down"/>
								</span>
								<span class="when-closed-chevron float-right">
									<b-icon icon="chevron-right"/>
								</span>
							</div>
						</b-nav-item>
					</div>
					<b-collapse id="collapse-actionplans" class="pl-2 text-left">
						<b-nav-item :to="{ name: 'patientIndexActionPlan' }" class="ml-2 sidebar-item">
							Action Plans	
						</b-nav-item>
					</b-collapse>
					<div v-if="!isResearcher && $store.getters.hasPermissionDailyQuestions">
						<b-nav-item :to="{ name: 'dailyQuestion' }" class="sidebar-item">
							HINTS
						</b-nav-item>
					</div>
					<b-nav-item v-if="!isResearcher && $store.getters.hasPermissionProfile" :to="{ name: 'patientProfile' }" class="sidebar-item">
						User Profile
					</b-nav-item>
					<b-nav-item :to="{name: 'apitesting'}" class="sidebar-item">
						API Testing
					</b-nav-item>
                </div>
			</b-nav>
			<template #footer>
				<b-button v-if="getLoggedIn" block @click="logOut" variant="primary" style="border-radius: 0">Sign out</b-button>
				<b-button v-else block :to="{ name: 'researcherLogin' }" variant="primary" style="border-radius: 0">Sign in</b-button>
			</template>
		</b-sidebar>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'Navbar',
	data() {
		return {
			sidebar: screen.width >= 800
		};
	},

	computed: {
		...mapGetters([
			'getLoggedIn',
			'getLoading',
		]),
		user() { 
			return this.$store.getters.getUser;
		},
		isResearcher() {
			return this.$store.getters.isResearcher;
		}
	},

	methods: {
		toggleSidebar(){
			this.$data.sidebar = !this.$data.sidebar;
		},
		logOut() {
			this.$store.dispatch('logout');
		},
	},
};
</script>
<style lang="css">
	#sidebar {
		background-color: var(--dark-blue) !important;
	}
</style>
<style lang="scss" scoped>
	.collapsed > a > div > .when-opened-chevron,
	:not(.collapsed) > a > div > .when-closed-chevron {
		display: none;
	}
	.header:hover > a > #showHover,
	:not(.header:hover) > a > #showNoHover {
		display: none;
	}
	.nav-link {
		text-decoration: none;
		text-transform: capitalize;
		transition:0.2s ease;
		color: var(--white);
	}
	.nav-link:hover{
		color: var(--dark);
	}
	.sidebar-item {
		transition: 0.2s ease;
		padding-right: 10px;
	}
	.sidebar-item:hover {
		background-color: var(--primary);
	}
	.navButton {
		position: fixed;
		margin-left: 10px;
		margin-top: 5px;
	}
	.navButtonClosed {
		transition: 0.3s ease-in-out; // this is same as sidebar from _sidebar.scss
	}
	.navButtonExpanded {
		margin-left: 330px;
		transition: 0.3s ease-in-out;
	}

</style>
