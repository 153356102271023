import Axios from 'axios';
import {
	API_URL,
	ENTRIES,
	GOALS,
	MEDICATION_RESPONSES,
	MEDICATION_SCHEDULES,
	MEDICATIONS,
	PATIENTS,
	STUDIES,
	TIMES,
	TAGS,
	MEASUREMENTS,
	WEIGHTS
} from '@/store/APIVars';
import { apiCall } from '@/store/apiCall';

const patient = {
	namespaced: true,
	state: () => ({
		patients: [],
		patient: null,
		responses: null,
		wasPostSuccessful: false,
		patientMedications: [],
	}),
	getters: {
		getPatients(state){
			return state.patients;
		},
		getPatient(state){
			return state.patient;
		}
	},
	mutations: {
		setPatients(state, payload){
			state.patients = payload;
		},
		addPatient(state, payload){
			state.patients.push(payload);
		},
		setPatient(state, payload){
			state.patient = payload;
		},
		setResponses(state, payload) {
			state.responses = payload;
		},
		setWasPostSuccessful(state, result) {
			state.wasPostSuccessful = result;
		},
		setPatientMedications(state, payload) {
			state.patientMedications = payload;
		},
	},
	actions: {
		// API calls
		async getAllPatients({ commit, getters, rootGetters }) {
			await Axios.get(API_URL + PATIENTS, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					return new Promise((resolve, reject) => {
						setTimeout(() => {
							commit('setPatients', data.data.response);
							resolve();
						}, 1000);
					});
				})
				.catch((err) => console.error(err));
		},
		async getPatient({ commit, getters, rootGetters }, patient_id) {
			await Axios.get(API_URL + PATIENTS + `/${patient_id}`, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('setPatient', data.data.response);
				})
				.catch((err) => console.error(err));
		},
		async createPatient({ commit, getters, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback, formData } = {}) {
			await apiCall(
				() => Axios.post(API_URL + PATIENTS, formData, {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState ? setState : (data) => { commit('addPatient', data.response); },
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async editPatient({ commit, getters, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback, formData } = {}) {
			await apiCall(
				() => Axios.put(API_URL + PATIENTS + '/' + formData.id, formData, {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState ? setState : (data) => { commit('setPatient', data.response); },
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async createPatientMedicationScheduleResponse({ commit, getters, rootGetters }, formData) {
			await Axios.post(API_URL + PATIENTS + '/' + MEDICATION_RESPONSES,
				formData,
				{
					headers: {
						Authorization: 'Bearer' + rootGetters.getAuthToken
					}
				}).then(() => {
				return new Promise((resolve, reject) => {
					setTimeout(() => {
						commit('setWasPostSuccessful', true);
						resolve();
					}, 1000);
				});
			}).catch((err) => {
				commit('setWasPostSuccessful', false);
				console.error(err);
			});
		},
		async getPatientStudies({ commit, getters, rootGetters }, patient_id) {
			await Axios.get(API_URL + PATIENTS + `/${patient_id}/` + STUDIES, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('studies/setStudies', data.data.response);
				})
				.catch((err) => console.error(err));
		},
		async getPatientEntries({ commit, getters, rootGetters }, patient_id) {
			await Axios.get(API_URL + PATIENTS + `/${patient_id}/` + ENTRIES, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('entries/setEntries', data.data.response);
				})
				.catch((err) => console.error(err));
		},
		async getPatientGoals({ commit, getters, rootGetters }, patient_id) {
			await Axios.get(API_URL + PATIENTS + `/${patient_id}/` + GOALS, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('goals/setGoals', data.data.response);
				})
				.catch((err) => console.error(err));
		},
		async getPatientMedicationSchedulesAndTimes({ commit, getters, rootGetters }, patient_id) {
			await Axios.get(API_URL + PATIENTS + `/${patient_id}/` + MEDICATIONS + '/' + MEDICATION_SCHEDULES + '/' + TIMES, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					commit('setPatientMedications', data.data.response);
					// console.log('Patient sch: ', data.data.response);
				})
				.catch((err) => console.error(err));
		},
		async getPatientMedicationResponses({ commit, getters, rootGetters }, {patient_id, medication_schedule_id} = {}) {
			await Axios.get(API_URL + PATIENTS + `/${patient_id}/` + MEDICATION_SCHEDULES + `/${medication_schedule_id}/` +  MEDICATION_RESPONSES, {
				headers: {
					Authorization: 'Bearer ' + rootGetters.getAuthToken
				}
			})
				.then((data) => {
					return new Promise((resolve, reject) => {
						setTimeout(() => {
							commit('setResponses', data.data.response);
							resolve();
						}, 1000);
					});
				})
				.catch((err) => console.error(err));
		},
		async getPatientAllMedicationScheduleResponses({commit, rootGetters}, { setState, modifier, callback, errorCondition, errorCallback, patient_id } = {}) {
			await apiCall(
				() => Axios.get(`${API_URL}${PATIENTS}/${patient_id}/${MEDICATION_SCHEDULES}/${MEDICATION_RESPONSES}`, {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState ? setState : (data) => { commit(`setPatientMedications`, data); },
				modifier,
				callback,
				errorCondition,
				errorCallback ? errorCallback : e => console.error(e)
			);
		},

		/* Get patient medication schedule. Contains the medication, reponses, and list of dates */
		async getPatientMedicationScheduleWithDates({commit, rootGetters}, {setState, modifier, callback, errorCondition, errorCallback,  patient_id, medication_schedule_id} = {}) {
			await apiCall(
				() => Axios.get(`${API_URL}${PATIENTS}/${patient_id}/${MEDICATION_SCHEDULES}/${medication_schedule_id}/schedule`, {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState ? setState : (data) => {commit('setPatientMedications', data);},
				modifier,
				callback,
				errorCondition,
				errorCallback ? errorCallback : e => console.error(e)
			);
		},

		async getPatientMedicationScheduleWithResponsePopulatedDates({commit, rootGetters}, {patient_id, medication_schedule_id} = {}) {
			const payload = await Axios.get(
				`${API_URL + PATIENTS}/${patient_id}/${MEDICATION_SCHEDULES}/${medication_schedule_id}}/schedule/${MEDICATION_RESPONSES}`,
				{headers: { Authorization: 'Bearer ' + rootGetters.getAuthToken }}
			);
			
			if (payload)
				commit('setPatientMedications', payload.data.response);
		},
		/* Get patient medication schedule. Contains the medication and list of dates as response lists. */
		async getPatientMedicationScheduleDatesAndResponses({commit, rootGetters}, {setState, modifier, callback, errorCondition, errorCallback,  patient_id, medication_schedule_id} = {}) {
			await apiCall(
				() => Axios.get(`${API_URL}${PATIENTS}/${patient_id}/${MEDICATION_SCHEDULES}/${medication_schedule_id}/schedule/${MEDICATION_RESPONSES}`, {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState ? setState : (data) => {commit('setPatientMedications', data);},
				modifier,
				callback,
				errorCondition,
				errorCallback ? errorCallback : e => console.error(e)
			);
		},
		async getPatientTags({ commit, getters, rootGetters }, { setState, modifier, callback, errorCondition, errorCallback, patient_id } = {}) {
			await apiCall(
				() => Axios.get(API_URL + PATIENTS + '/' + patient_id + '/' + TAGS, {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					},
				}),
				setState ? setState : (data) => { commit('quizzes/setQuestionCategories', data.response); },
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
		async getPatientWeights(
			{
				commit,
				getters,
				rootGetters
			}, {
				setState,
				modifier,
				callback,
				errorCondition,
				errorCallback,
				patient_id 
			} = {}
		) {
			return await apiCall(
				() => Axios.get(
					API_URL + PATIENTS + '/' + patient_id + '/'  + MEASUREMENTS + '/' + WEIGHTS,
					{headers: {Authorization: 'Bearer ' + rootGetters.getAuthToken}}
				),
				setState,
				modifier,
				callback,
				errorCondition,
				errorCallback
			);
		},
	},
};

export default patient;
