import NavigationGuards from './NavigationGuards';

export default [
	{ // researchers only
		path: '/questions',
		name: 'indexQuestion',
		component: () => import('../pages/quizzes/manageQuestions')
	},
	{ // researchers only
		path: '/questions/create',
		name: 'createQuestion',
		component: () => import('../pages/quizzes/createEditQuestion')
	},
	{ // researchers only
		path: '/questions/:questionId',
		name: 'editQuestion',
		component: () => import('../pages/quizzes/createEditQuestion'),
		props: (route) => {
			return {
				questionId: +route.params.questionId
			};
		}
	},
	{ // researchers only
		path: '/questions/:questionId/edit',
		name: 'showQuestion',
		component: () => import('../pages/quizzes/createEditQuestion'),
		props: (route) => {
			return {
				questionId: +route.params.questionId
			};
		}
	},
	{ // patient only
		path: '/profile/daily-question',
		name: 'dailyQuestion',
		component: () => import('../pages/quizzes/answerDailyQuestion'),
		beforeEnter: NavigationGuards.dailyQuestionsPatientGuard
	}
];